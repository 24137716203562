import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { ApiConstant } from '../../constants/api-constant';
import { CommonConstant } from '../../constants/common-constant';
import { catchError, map, tap } from 'rxjs/operators';
import { BehaviorSubject, Observable, Subject, throwError } from 'rxjs';
import { LocalstorageService } from '../../services/localstorage.service';
import _ from 'underscore';
import { CommonService } from '../../services/common.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {popupParameter} from '../table/table.component';
declare let d3: any;

@Injectable({
  providedIn: 'root'
})

export class ChartsService {
    [x: string]: any;
     updatedData : any;
     unique_x_axis : any;
     yoyColors = ['rgb(255, 187, 120)', '#65BDB1', '#1F77B4', 'red'];
     chartColors = ['rgb(255, 187, 120)', '#705F93', '#F7F37B', 'rgb(152, 223, 138)', 'rgb(31, 119, 180)', 'rgb(150, 222, 218)', 'rgb(101, 189, 177)', 'rgb(118, 145, 190)', 'rgb(255, 127, 14)', '#A4C079'];
     sapOrTapToggle : any = CommonConstant.sapOrTapToggle;
     resetChart = new Subject<any>();
     showYoyTooltip = new Subject<any>();
     activeTabId : any;
     yoyFilterDate: any;
     constructor(
         private http : HttpClient,
         private localStorageService: LocalstorageService,
         private commonService: CommonService,
         private modalService: NgbModal){
            this.activeTabId = this.localStorageService.get('activeTabId');          
         }

    setYoyFilterDate(val){
        this.yoyFilterDate = val;
    }

    getYoyFilterDate(){
        return this.yoyFilterDate;
    }

     getUpdatedData() {
         return this.updatedData;
     }

     getChart= (chartObject, object) => {
         var that = this;
         var returnObject = {};
         var properties;
         var apiUrl = environment.baseURL +''+chartObject.api_name;
         let goalPercentage = object.goal;
         this.sapOrTapToggle = this.commonService.getSapOrTapToggle();
         return this.http.post(apiUrl, object).pipe(
            map(response => {
             var resObj= {};
             var updatedResponce = null;
             switch (chartObject.chart_type) {
                case 'lineChart':
                    if( chartObject.api_name === 'getYearOnYearComparision'){
                        updatedResponce = that.getModifiedData(chartObject.chart_id, response);
                        properties = that.getLineYOYComparisonChartProperties(response, chartObject);
                    } else {
                        updatedResponce = that.getModifiedData(chartObject.chart_type, response);
                        properties = that.getLineChartProperties(response, chartObject);
                    }
                    break;
                 case 'YOYLineChart':
                     that.isWeeklyChart = object.isWeeklyChart;
                     if (!object.is90DayYOYChart) {
                         if(object.isWeeklyChart){
                             updatedResponce = that.getModifiedData('YOYLineChart', response);
                             properties = that.getYOYLineChartProperties(response, chartObject);
                         } else {
                             updatedResponce = that.getModifiedData('90DayYOYLineChart', response);
                             properties = that.getYOYLineChartProperties(response, chartObject);
                         }
                     } else {
                         updatedResponce = that.getModifiedData(chartObject.chart_type, response);
                         properties = that.getYOYLineChartProperties(response, chartObject);
                     }
                     break;
                 case 'YOYBarChart':
                     that.isWeeklyChart = object.isWeeklyChart;
                     if (!object.is90DayYOYChart) {
                         if(object.isWeeklyChart){
                             updatedResponce = that.getModifiedData('YOYBarChart', response);
                             properties = that.getYOYBarChartProperties(response, chartObject);
                         } else {
                             updatedResponce = that.getModifiedData('90DayYOYLineChart', response);
                             properties = that.getYOYBarChartProperties(response, chartObject);
                         }
                     } else {
                         updatedResponce = that.getModifiedData(chartObject.chart_type, response);
                         properties = that.getYOYBarChartProperties(response, chartObject);
                     }
                     break;
                 case 'stackedAreaChart':
                     updatedResponce = that.getModifiedData(chartObject.chart_type, response);
                     properties = that.getStackedAreaChartProperties(response, chartObject, that);
                     break;
                 case 'pieChart':
                     updatedResponce = that.getModifiedData(chartObject.chart_type, response);
                     properties = that.getPieChartProperties(response, chartObject);
                     break;
                 case 'multiBarChart':
                     updatedResponce = that.getModifiedData(chartObject.chart_type, response);
                     properties = that.getMultiBarChartProperties(response, chartObject, that);
                     break;
                 case 'linePlusBarChart':
                     updatedResponce = that.getModifiedData(chartObject.chart_type, response);
                     properties = that.getLinePlusBarChartProperties(response, chartObject);
                     break;
                 case 'sunburstChart':
                     updatedResponce = that.getModifiedData(chartObject.chart_type, response);
                     properties = that.getSunburstChartProperties();
                     break;
                 case 'map':
                     updatedResponce = that.getModifiedData(chartObject.chart_type, response);
                     properties = that.getMapProperties(updatedResponce.data, chartObject, that);
                     break;
             }
             resObj['chart_data'] = updatedResponce ? updatedResponce.data : response;
             resObj['tableData'] = response;
             resObj['chart_options'] = properties;
             return resObj;
            }
         ));
     }

     getChartData=(chartObject)=>{
         var that = this;
         var properties;
         var updatedResponce = null;
         var response = {};
         this.sapOrTapToggle = this.commonService.getSapOrTapToggle();
         switch (chartObject.chart_type) {
             case 'lineChart':
                 updatedResponce = that.getModifiedData(chartObject.chart_type, chartObject.tableData);
                 properties = that.getLineChartProperties(chartObject.tableData, chartObject);
                 break;
             case 'stackedAreaChart':
                 updatedResponce = that.getModifiedData(chartObject.chart_type, chartObject.tableData);
                 properties = that.getStackedAreaChartProperties(chartObject.tableData, chartObject, that);
                 break;
             case 'multiBarChart':
                 updatedResponce = that.getModifiedData(chartObject.chart_type, chartObject.tableData);
                 properties = that.getMultiBarChartProperties(chartObject.tableData, chartObject, that);
                 break;
             case 'map':
                 updatedResponce = that.getModifiedData(chartObject.chart_type, chartObject.tableData);
                 properties = that.getMapProperties(updatedResponce.data, chartObject, that);
                 break;
         }
         response['chart_data'] = updatedResponce ? updatedResponce.data: chartObject.tableData;
         response['chart_options'] = properties;
         return response;
     }

     getStackedAreaChartProperties =(response, chartObject, that) => {
         var categories = _.uniq(_.pluck(_.flatten(response), 'xLabel'));
         var toggleType = $('#toggle_container .toggleActive').attr('type') === '$' ? '$' : '';
         // var colors = ['rgb(255, 152, 150)', 'rgb(214, 39, 40)', 'rgb(255, 187, 120)', 'rgb(152, 223, 138)', 'rgb(31, 119, 180)', 'rgb(150, 222, 218)', 'rgb(101, 189, 177)', 'rgb(118, 145, 190)', 'rgb(255, 127, 14)'];
         return {
             chart: {
                 wrapLabels: false,
                 showLabels: true,
                 type: chartObject.chart_type,
                 staggerLabels: true,
                 reduceXTicks: false,
                 height: chartObject.height,
                 useInteractiveGuideline: true,
                 useVoronoi: false,
                 clipEdge: false,
                 area: true,
                 showControls: false,
                 showValues: true,
                 margin: {
                     bottom: 50
                 },
                 interactiveLayer: {
                     tooltip: {
                         contentGenerator: function (e) {
                             var value = e.value;
                             var rows = '';
                             var totalRows = '';
                             var totals = 0;
                             var totalsKey = '';
                             e.series.reverse().forEach(function (ele) {
                                 if (ele.key === 'TOTAL') {
                                     totals = ele.value;
                                     totalsKey = 'TOTALS';
                                 } else {
                                     if (ele.value != 0) {
                                         rows = rows +
                                             '<tr>' +
                                             '<td class=\'x-value\' style=\'padding-right: 12px;\'><span class=\'tooltipLegend\' style=\'background:' + ele.color + '\'></span>' + ele.key + '</td>' +
                                             '<td class=\'x-value\'>' + toggleType + ' ' + d3.format(',')(ele.value) + '</td>' +
                                             '</tr>';
                                     }
                                 }
                             });

                             if (e.series.length === 1) {
                                 totalRows = '';
                             } else {
                                 totalRows = totalRows +
                                     '<tr style="background: #1ABB9A;">' +
                                     '<td></td>' +
                                     '<td></td>' +
                                     '</tr>' +
                                     '<tr>' +
                                     '<td class=\'x-value\' style=\'padding-right: 12px;\'><span class=\'tooltipLegend\'></span>' + totalsKey + '</td>' +
                                     '<td class=\'x-value\'> ' + toggleType + ' ' + d3.format(',')(totals) + '</td>' +
                                     '</tr>';
                             }

                             var header =
                                 '<thead>' +
                                 '</thead>';

                             return '<span class=\'tooltipHeader\'>' + categories[value - 1] + '</span>' +
                                 '<table>' +
                                 header +
                                 '<tbody>' +
                                 rows +
                                 totalRows +
                                 '</tbody>' +
                                 '</table>';
                         }
                     }
                 },

                 valueFormat: function (d) {
                     return d3.format('d')(d);
                 },
                 tooltips: true,
                 color: this.chartColors,
                 x: function (d) {
                     if (typeof d !== 'undefined' && d !== null) {
                         return d[0];
                     }
                 },
                 y: function (d) {
                     if (typeof d !== 'undefined' && d !== null) {
                         return d[1];
                     }
                 },
                 xAxis: {
                     axisLabel: '',
                     showMaxMin: false,
                     orient: 'bottom',
                     tickFormat: function (category) {
                         return categories[category - 1];
                     },
                     ticks: 100,
                     rotateLabels: -45
                 },
                 yAxis: {
                     axisLabel: '',
                     tickFormat: function (d) {
                         //return toggleType + ' '+ d;
                         if (toggleType === '$') {
                             return toggleType + ' ' + d3.format('.3s')(d);
                         } else {
                             return toggleType + ' ' + d3.format('.3s')(d);
                         }
                     }
                 },
                 stacked: {
                     dispatch: {
                         areaClick:  (e)=> {
                             let products = this.commonService.productList;
                             if(products){
                                var filteredArray = products.filter(function (item) {
                                    return item['category'] === e.series;
                                });
                                 $('#productFilter li:not(.init)').each(function (index, ele) {
                                    // if ($(ele).attr('data-value') === e.series) {
                                    if ($(ele)[0].innerText === e.series){
                                        $('#productFilter .init').html(e.series);
                                        $('#productFilter .selected').removeClass('selected');
                                        $(ele).addClass('selected');
                                    }
                                });
                                this.commonService.setSelector('product', e.series);
                                if (filteredArray.length) {
                                    this.commonService.setParameters('param7', 'category');
                                    //$('#productFilter').val(e.series);
                                    // that.options_selector.filter(function (selector) {
                                    //     if (selector.id === 'productFilter') {
                                    //         selector.selected_value = e.series;
                                    //     }
                                    //     return true;
                                    // });
                                } else {
                                    this.commonService.setParameters('param7', 'sub_category');
                                }
                                $('.nvtooltip').hide();
                                this.resetChart.next();
                             }
                             
                             if(this.commonService.getMedicareAdvantageFlag() && ['medicare advantage','med adv'].includes(e.series.toLowerCase())){
                                $('#medicareAdvantageFilterContainer').show();
                             }

                            //  if ((that.medicareAdvantageSaleType && that.medicareAdvantageSaleType.toLowerCase() === 'yes' && e.series === 'Medicare Advantage') || (that.medicareAdvantageSaleType && that.medicareAdvantageSaleType.toLowerCase() === 'yes' && e.series === 'Med Adv')) {
                            //      $('#medicareAdvantageFilterContainer').show();
                            //      that.medicareAdvantageFilter = {
                            //          filterValue: 'All'
                            //      };
                            //      that.param12 = 'All';
                            //      $('#saleTypeAll').css('color', '#1ABB9A');
                            //  }
                            //  that.getInfolables(that.getParameters());
                            //  that.getData();
                         }
                     }
                 },
                 showTotalInTooltip: true
             }
         };
     }

     getMultiBarChartProperties= (response, chartObject, that) =>{
         that.barChartCategories = _.uniq(_.pluck(_.flatten(response), 'xlabel'));
         var toggleType = $('#toggle_container .toggleActive').attr('type') === '$' ? '$' : '';
         var chart = {
             //width: categories.length * 65,//65 is a bar width we need to apply
             type: chartObject.chart_type,
             height: chartObject.height,
             margin: chartObject.margin,
             clipEdge: true,
             duration: 500,
             stacked: chartObject.stacked,
             showControls: true,
             tooltips: true,
             color: this.chartColors,
             useInteractiveGuideline: true,
             reduceXTicks: false,
          
             interactiveLayer: {
                 tooltip: {
                     contentGenerator: (e)=> {
                         var value = e.value;
                         var rows = '';
                         var totalRows = '';
                         var totals = 0;
                         var totalsKey = '';
                         e.series.reverse().forEach( (ele)=> {
                             // if (ele.key === 'TOTAL') {
                             totals += ele.value;
                             totalsKey = 'TOTALS';
                             // } else {
                             if (ele.value != 0) {
                                 rows = rows +
                                     '<tr>' +
                                     '<td class=\'x-value\' style=\'padding-right: 12px;\'><span class=\'tooltipLegend\' style=\'background:' + ele.color + '\'></span>' + ele.key + '</td>' +
                                     '<td class=\'x-value\'>' + toggleType + ' ' + d3.format(',')(ele.value) + '</td>' +
                                     '</tr>';
                             }
                             // }
                         });
                         if (e.series.length === 1) {
                             totalRows = '';
                         } else {
                             totalRows = totalRows +
                                 '<tr style="background: #1ABB9A;">' +
                                 '<td></td>' +
                                 '<td></td>' +
                                 '</tr>' +
                                 '<tr>' +
                                 '<td class=\'x-value\' style=\'padding-right: 12px;\'><span class=\'tooltipLegend\'></span>' + totalsKey + '</td>' +
                                 '<td class=\'x-value\'> ' + toggleType + ' ' + d3.format(',')(totals) + '</td>' +
                                 '</tr>';
                         }

                         var header =
                             '<thead>' +
                             '</thead>';

                         return '<span class=\'tooltipHeader\'>' + that.barChartCategories[e.value - 1] + '</span>' +
                             '<table>' +
                             header +
                             '<tbody>' +
                             rows +
                             totalRows +
                             '</tbody>' +
                             '</table>';
                     }
                     // contentGenerator: function(e) {
                     //     var series = e.series[0];
                     //     if (series.value === null) return;
                     //     var rows =
                     //         '<tr>' +
                     //         '<td class=\'x-value\' style=\'padding-right: 12px;\'>' + categories[e.data.x - 1] + ' </td>' +
                     //         '<td class=\'x-value\'>' + toggleType + ' ' + d3.format(',')(e.data.y) + '</td>' +
                     //         '</tr>';

                     //     var header = '<thead></thead>';

                     //     return '<span class=\'tooltipHeader\'>' + e.data.key + '</span>' +
                     //         '<table>' +
                     //         header +
                     //         '<tbody>' +
                     //         rows +
                     //         '</tbody>' +
                     //         '</table>';
                     // }
                 }                 
             },               
             xAxis: {
                 axisLabel: '',
                 showMaxMin: false,
                 orient: 'bottom',
                 tickFormat: (d) =>{
                     return that.barChartCategories[d - 1];
                 },
                // tickFormat: (d) => {
                //     // console.log(that.barChartCategories, "@@@",d);
                //     const label = that.barChartCategories[d - 1]; //d.toString(); // Get the x-axis label as string
                //     return label.match(/\b(\w)/g).join('')
                    // Split the label into words
                    // const words = that.barChartCategories[d - 1].split(' ');
                    // // Set a maximum of 3 words per line
                    // const maxWordsPerLine = 3;
                    // let label = '';

                    // // Loop through the words and add <tspan> elements with line breaks
                    // for (let i = 0; i < words.length; i++) {
                    // if (i > 0 && i % maxWordsPerLine === 0) {
                    //     label += '<tspan x="0" dy="15">' + words[i] + '</tspan>';
                    // } else {
                    //     label += ' ' + words[i];
                    // }
                    // }

                    // // return '<text>' + label + '</text>';
                    // return '<text [innerHTML]="\'' + label + '\'""></text>';



                    // const maxChars = 5; // Set the maximum number of characters per line
                    // const words = label.split(' '); // Split the label by space
                    // return words[0];
                    // let truncatedLabel = ''; // Initialize the truncated label
                    // let line = ''; // Initialize the current line
                    // for (let i = 0; i < words.length; i++) {
                    //   if ((line + words[i]).length <= maxChars) {
                    //     line += (line === '' ? '' : ' ') + words[i]; // Add word to current line
                    //   } else {
                    //     truncatedLabel += (truncatedLabel === '' ? '' : ' ') + line; // Add current line to truncated label
                    //     line = words[i]; // Start new line with current word
                    //   }
                    // }
                    // truncatedLabel += (truncatedLabel === '' ? '' : ' ') + line; // Add last line to truncated label
                    // // return truncatedLabel.replace(/<br>/g, '\n');
                    // return truncatedLabel.split(' ')[0]; // Return truncated label with line breaks
                //   },
                 ticks: 100,
                 rotateLabels: -30
             },
             yAxis: {
                 axisLabel: '',
                 tickFormat: (d) => {
                     if (toggleType === '$') {
                         return toggleType + ' ' + d3.format('.3s')(d);
                     } else {
                         return toggleType + ' ' + d3.format('.3s')(d);
                     }
                 }
             },
             multibar: {
                 dispatch: {
                     elementClick: (e) =>{
                        $('.nvtooltip').hide();
                        let products = this.commonService.productList;
                        if(products){
                            var filteredArray = products.filter(function (item) {
                                return item['category'] === e.data.key;
                            });
                            $('#productFilter li:not(.init)').each(function (index, ele) {
                                // if ($(ele).attr('data-value') === e.series) {
                                if ($(ele)[0].innerText === e.series.key){
                                    $('#productFilter .init').html(e.series.key);
                                    $('#productFilter .selected').removeClass('selected');
                                    $(ele).addClass('selected');
                                }
                            });
                           this.commonService.setSelector('product', e.data.key);
                           if (filteredArray.length) {
                               this.commonService.setParameters('param7', 'category');
                               $('#productFilter').val(e.data.key);
                               //$('#productFilter').val(e.series);
                               // that.options_selector.filter(function (selector) {
                               //     if (selector.id === 'productFilter') {
                               //         selector.selected_value = e.series;
                               //     }
                               //     return true;
                               // });
                           } else {
                               this.commonService.setParameters('param7', 'sub_category');
                           }
                           this.resetChart.next();
                        }

                        if(this.commonService.getMedicareAdvantageFlag() && ['medicare advantage','med adv'].includes(e.series.key.toLowerCase())){
                            $('#medicareAdvantageFilterContainer').show();
                         }
                        //  var filteredArray = that.products.filter(function (item) {
                        //      return item.category === e.data.key;
                        //  });
                        //  $('#productFilter li:not(.init)').each(function (index, ele) {
                        //      if ($(ele).attr('data-value') === e.data.key) {
                        //          $('#productFilter .init').html(e.data.key);
                        //          $('#productFilter .selected').removeClass('selected');
                        //          $(ele).addClass('selected');
                        //      }
                        //  });
                        //  if (filteredArray.length) {
                        //     that.param7 = 'category';
                        //     $('#productFilter').val(e.data.key);
                        //  } else {
                        //     that.param7 = 'sub_category';
                        //  }
                        //  if ((that.medicareAdvantageSaleType && that.medicareAdvantageSaleType.toLowerCase() === 'yes' && e.data.key === 'Medicare Advantage') || (that.medicareAdvantageSaleType && that.medicareAdvantageSaleType.toLowerCase() === 'yes' && e.data.key === 'Med Adv')) {
                        //      $('#medicareAdvantageFilterContainer').show();
                        //      that.medicareAdvantageFilter = {
                        //          filterValue: 'All'
                        //      };
                        //      that.param12 = 'All';
                        //      $('#saleTypeAll').css('color', '#1ABB9A');
                        //  }
                        //  that.param2 = e.data.key;
                        //  that.getInfolables(that.getParameters());
                        //  that.getData();
                     }
                 }
             },
             controls: {
                    dispatch: {
                        legendClick: function (e) {
                            // console.log(e)
                        }     
                       }
             },
             showTotalInTooltip: true
         };
         if (that.barChartCategories.length > 20) {
             chart['width'] = that.barChartCategories.length * (window.innerWidth * 0.05);
         }
         return {
             chart: chart
         };
     }

     getLinePlusBarChartProperties =(response, chartObject) => {
         var that = this;
         that.xLabels = _.uniq(_.pluck(_.flatten(response), 'xLabel'));
         var height = $(window).width() >= 1024 ? 300 : 200;
         return {
             chart: {
                 type: chartObject.chart_type,
                 focusEnable: false,
                 reduceXTicks: false,
                 height: height,
                 margin: {
                     top: 30,
                     right: 75,
                     bottom: 65,
                     left: 60
                 },
                 noData: 'No Data Available',
                 bars: {
                     forceY: [0]
                 },
                 lines: {
                     forceY: [0]
                 },
                 x: function (d, i) {
                     return i;
                 },
                 xAxis: {
                     axisLabel: '',
                     showMaxMin: false,
                     orient: 'bottom',
                     tickFormat: function (d) {
                         return that.xLabels[d];
                     },
                     ticks: 100,
                     rotateLabels: -45
                 },

                 y1Axis: {
                     axisLabel: '',
                     tickFormat: function (d) {
                         return '$ ' + d3.format('.3s')(d);
                     },
                     axisLabelDistance: -10
                 },
                 y2Axis: {
                     axisLabel: '',
                     tickFormat: function (d) {
                         return d3.format('d')(d);
                     }
                 },
                 tooltip: {
                     contentGenerator: function (e) {
                         if (e.data) {
                             return 'Sale' + ' $ ' + d3.format(',')(e.data.y);
                         } else {
                             return 'Policies' + '     ' + e.point.y;
                         }
                     }
                 }

             }
         };
     }

    getPieChartProperties= (response, chartObject)=> {
        var height = $(window).width() >= 1024 ? 200 : 150;
        var colors = ['rgb(255, 152, 150)', 'rgb(214, 39, 40)', 'rgb(255, 187, 120)', 'rgb(152, 223, 138)', 'rgb(31, 119, 180)', 'rgb(150, 222, 218)', 'rgb(101, 189, 177)', 'rgb(118, 145, 190)', 'rgb(255, 127, 14)'];
        // console.log('response',response);
        return {
            chart: {
                type: chartObject.chart_type,
                height: 200+(response?.length/3)*30,
                // height: chartObject.height?chartObject.height:200,
                width: chartObject.width?chartObject.width:500,
                color: colors,
                donut: chartObject.donut,
                x: function (d) { return d.key; },
                y: function (d) { return d.y; },
                showLabels: false,
                duration: 500,
                labelThreshold: 0.01,
                noData: 'No Data Available',
                labelSunbeamLayout: true,
                legend: {
                    margin: {
                        top: 5,
                        right: 35,
                        bottom: 5,
                        left: 0
                    }
                },
                tooltips: true,
                tooltip: {
                    contentGenerator: function (e) {
                        var label = '$';
                        if ($('#businessByToggleBtn .active').attr('type') === '#' || $('#businessByToggleBtn .active').attr('type') === 'applications') {
                            label = '';
                        }
                        return e.data.key + ' ' + label + '' + d3.format(',')(e.data.y.toFixed(2));
                    }
                }
            }
        };
    }

     getSunburstChartProperties= ()=>{
         return {
             chart: {
                 type: 'sunburstChart',
                 height: 500,
                 duration: 250,
                 mode: 'size',
                 tooltips: true,
                 tooltip: {
                     contentGenerator: function (e) {
                         if (e.data.name !== 'unknown') {
                             $('.nvtooltip').show();
                             var selector = $('#toggle .toggleActive').attr('type') === '$' ? '$' : '';
                             return e.data.name + ':&nbsp;&nbsp;&nbsp;  ' + selector + ' ' + d3.format(',')(e.data.value.toFixed(2));
                         } else {
                             $('.nvtooltip').hide();
                         }
                     }
                 }
                 // callback: function () {
                 //     d3.selectAll(".arc-container").on('click',
                 //         function(e){
                 //             if (e.name === 'unknown') {
                 //                 d3.selectAll(".arc-container").hide()
                 //                 d3.event.stopPropagation();
                 //                 d3.event.preventDefault();
                 //                 console.log("=======", d3.event);
                 //             }
                 //         });
                 // },
                 // sunburst: {
                 //     dispatch: {
                 //         elementClick: function (e) {
                 //             console.log('------------');
                 //         }
                 //     }
                 // }
             }
         };
     }

     getMapProperties= (response, chartObject, that)=> {
        let userInfo = JSON.parse(localStorage.getItem('userInfo'));
        let userRole = userInfo['role'];
        let isTAPRequired = userInfo['isTAPRequired'];
         var saleColorObj : any = {
             'defaultFill': 'rgb(213, 222, 217)',
             '0 - 50,000': '#FFF09E',
             '50,000 - 1,00,000': '#F7D885',
             '1,00,000 - 2,00,000': '#FFB658',
             '2,00,000 - 3,00,000':'#F78647',
             '> 3,00,000': '#ED4D47'
         };
         var policyColorObj : any = {
             'defaultFill': 'rgb(213, 222, 217)',
             '0 - 500': '#FFF09E',
             '500 - 1,000': '#F7D885',
             '1,000 - 5,000': '#FFB658',
             '5,000 - 10,000': '#F78647',
             '> 10,000': '#ED4D47'
         }
         if (userRole != 'Manager') {
             policyColorObj = {
                 'defaultFill': 'rgb(213, 222, 217)',
                 '0 - 100': '#FFF09E',
                 '100 - 200': '#F7D885',
                 '200 - 300': '#FFB658',
                 '300 - 500': '#F78647',
                 '> 500': '#ED4D47'
             };
             saleColorObj = {
                 'defaultFill': 'rgb(213, 222, 217)',
                 '0 - 2,500': '#FFF09E',
                 '2,500 - 5,000': '#F7D885',
                 '5,000 - 7,500': '#FFB658',
                 '7,500 - 12,000': '#F78647',
                 '> 12,000': '#ED4D47'
             };
         }
         that.all_state_data = response;
         var toggleType = '';
         if (that.activeTabId == 5) {
             toggleType = toggleType = $('#toggleReport .toggleActive').attr('type') === '$' ? '$' : '';
         } else {
             toggleType = $('#toggle_container .toggleActive').attr('type') === '$' ? '$' : '';
         }
         //var toggleType = $('.toggleActive').attr('type')==='$'?$('.toggleActive').attr('type'):'';
         var value = toggleType === '$' ? 'earned_premiums' : 'active_policies';
         if (that && isTAPRequired === 'yes' && that.sapOrTapToggle.mapDataTapValue !== 'sap') {
             if (value === 'earned_premiums') {
                 value = 'tap_earned_premiums';
             } else if (value === 'active_policies') {
                 value = 'tap_active_policies';
             }
         }
         return {
             scope: 'usa',
             element: document.getElementById('datamap'),
             fills: toggleType === '$' ? saleColorObj : policyColorObj,
             width: 900,
             height: 400,
             data: response,
             geographyConfig: {
                 popupTemplate: function (geo, data) {
                     return ['<div class=\'hoverinfo\'>',
                         geo.properties.name,
                         '&nbsp; &nbsp; ' + toggleType + '' + data[value].toLocaleString('en'),
                         '</div>'
                     ].join('');
                 },
                 popupOnHover: true,
                 highlightBorderColor: '#bada55',
                 highlightBorderWidth: 3
             },
             done: function (datamap) {
                 datamap.svg.selectAll('.datamaps-subunit').on('click', function (geography) {
                     that.selectedState = geography.id;
                     // that.getData();
                     that.openModal(geography);
                 });
             }
         };
     }

     getBarPlusLineChartData= (chartType, response)=> {
         var updatedResponce = this.getModifiedData(chartType, response);
         return updatedResponce['data'];
     }

     getYOYLineChartProperties= (response, chartObject) =>{
         var toggleType = $('#toggle_container .toggleActive').attr('type') === '$' ? '$' : '';
         return {
             chart: {
                 type: 'multiChart',
                 wrapLabels: false,
                 showLabels: true,
                 staggerLabels: true,
                 reduceXTicks: false,
                 useVoronoi: true,
                 clipEdge: true,
                 useInteractiveGuideline: true,
                 area: false,
                 showControls: false,
                 showValues: true,
                 // forceY: [0],
                 noData: "No Data Available",
                 height: 400,
                 margin: {
                     top: 30,
                     right: 20,
                     bottom: 50,
                     left: 70
                 },
                 // color: d3.scale.category10().range(),
                 color: this.yoyColors,
                 showTotalInTooltip: true,
                 duration: 500,
                 stacked: false,
                 valueFormat: function (d) {
                     return d3.format('d')(d);
                 },
                 // bars1: {
                 // stacked: true
                 // },
                 // bars2: {
                 // stacked: true
                 // },
                 x: function (d) {
                     if (typeof d !== 'undefined' && d !== null) {
                         return d['x1'];
                     }
                 },
                 y: function (d) {
                     if (typeof d !== 'undefined' && d !== null) {
                         return d['y'];
                     }
                 },
                 y0: function (d) {
                     if (typeof d !== 'undefined' && d !== null) {
                         return d['y'];
                     }
                 },
                 bars1:{
                     groupSpacing:0,
                 },
                 lines1: {
                     forceY: [0],
                     // padData: true
                 },
                 xAxis: {
                     orient: 'bottom',
                     tickFormat: (d)=> {
                         // return d3.time.format('%m-%d')(new Date(d));
                         return this.unique_x_axis[d];
                     },
                     ticks: 10,
                     tickPadding: 10,
                     // rotateLabels: -45
                 },
                 yAxis1: {
                     showMaxMin: false,
                     ticks: 10,
                     tickFormat: function (d) {
                         if (toggleType === '$') {
                             return toggleType + ' ' + formatYAxis(d);
                         } else {
                             return toggleType + ' ' + formatYAxis(d);
                         }
                     }
                 },
                 interactiveLayer: {
                     tooltip: {
                         contentGenerator: (e)=> {
                             var rows = '';
                             var totalRows = '';
                             var totalsKey = '';
                             var bodyRows = '';
                             var totals = 0;
                             var uniqueCategory = [];
                             var column = 'Policies';
                             if (toggleType === '$') {
                                 column = 'Amount';
                             }
                             var tot = '';
                             rows = '<tr><td style=\'padding-right: 12px;font-weight:bold\'> Category/Sub Category </td>';
                             e.series.forEach((ele, index) =>{
                                 if (ele.key === 'TOTAL') {
                                 } else {
                                     // if (ele.key === 'Goal') {
                                     //     toggleType === '$' ? column = 'GoalAmount' : column = 'GoalPolicies';
                                     // } else {
                                     //     toggleType === '$' ? column = 'Amount' : column = 'Policies';
                                     // }
                                     totalsKey = 'TOTALS';
                                     rows += '<td class=\'x-value\' style=\'padding-right: 12px;font-weight:bold\'><span class=\'tooltipLegend\' style=\'background:' + ele.color + '\'></span>' + ele.key + '</td>'
                                     if (ele.data.data) {
                                         ele.data.data.forEach((ele1, index) => {
                                             let obj = uniqueCategory.findIndex(o => o[ele1.Category]);
                                             if (obj <= -1) {
                                                 if(ele1[column]){
                                                     uniqueCategory.push({
                                                         [ele1.Category]: [{ [ele.key]: ele1[column] ? ele1[column] : 0 }]
                                                     });
                                                 }
                                             } else {
                                                 if(ele1[column]){
                                                     uniqueCategory[obj][ele1.Category].push({ [ele.key]: ele1[column] ? ele1[column] : 0 })
                                                 }
                                             }
                                             if (ele.key === 'Goal') {
                                                 totals += ele1[column];
                                             }
                                         })
                                     }
                                     if (ele.key === 'Goal') {
                                         tot += '<td> <span class=\'tooltipLegend\' > </span>' + toggleType + ' ' + d3.format(',')(totals) + '</td>'
                                     } else {
                                         tot += '<td> <span class=\'tooltipLegend\' > </span>' + toggleType + ' ' + d3.format(',')(ele.value) + '</td>'
                                     }
                                 }
                             });

                            for (const [key , value] of Object.entries(uniqueCategory)) {
                                if (Object.keys(value) && Object.keys(value)[0] !== "null") {
                                    bodyRows += '<tr> <td>' + Object.keys(value) + '</td>';
                                    let obj:any = Object.keys(value);
                                    var arr = value[obj];
                                    e.series.forEach(function (ele, index) {
                                        if (ele.key === 'TOTAL') {
                                        } else {
                                            var year = arr.find(eleme => eleme[ele.key])
                                            if (year) {
                                                bodyRows += '<td ><span class=\'tooltipLegend\' > </span>' + toggleType + d3.format(',')(year[ele.key]) + '</td>'
                                            } else {
                                                bodyRows += '<td ><span class=\'tooltipLegend\' > </span> ' + toggleType + '0</td>'
                                            }
                                        }
                                    })
                                    bodyRows += '</tr>'
                                }
                            }
                             rows += '</tr>';
                             totalRows = totalRows +
                                 '<tr style="background: #1ABB9A;">' +
                                 '<td></td>' +
                                 '<td></td>' +
                                 '<td></td>' +
                                 '<td></td>' +
                                 '<td></td>' +
                                 '</tr>' +
                                 '<tr >' +
                                 '<td class=\'x-value\' >' + totalsKey + '</td>' + tot +
                                 '</tr>';

                             var header = '<thead></thead>';

                             return '<span class=\'tooltipHeader\'>' + this.unique_x_axis[e.value] + '</span>' +
                                 '<table>' +
                                 header +
                                 '<tbody>' +
                                 rows +
                                 bodyRows +
                                 totalRows +
                                 '</tbody>' +
                                 '</table>';
                                 // '<span style="font-style:italic: text-align: left;font-weight:bold;"> <sup>*</sup> Goal based on previous year performance</span>';
                         }
                     }
                 },
                 // callback: function(chart){
                 //     if (chart && chart.interactiveLayer) {
                 //     }
                 // }
                 // yAxis2: {
                 //     tickFormat: function (d) {
                 //         if (toggleType === '$') {
                 //             return toggleType + ' ' + formatYAxis(d);
                 //         } else {
                 //             return toggleType + ' ' + formatYAxis(d);
                 //         }
                 //     }
                 // },
                 // legend: {
                 //     dispatch: {
                 //         legendClick: function (e) {
                 //             // that.$broadcast('updateYOYChart', e);
                 //         },
                 //         stateChange: function (e) {
                 //             // that.$broadcast('resetYOYChart', e);
                 //         }
                 //     }
                 // },
                 // stack1: {
                 //     dispatch: {
                 //         areaClick: function (e) {
                 //             // if (updatedData.length && (updatedData[0].key === e.series)) {
                 //             //     updatedData = [];
                 //             // } else {
                 //             //     updatedData = [e.point];
                 //             // }
                 //         }
                 //     },
                 //     useInteractiveGuideline: true
                 // },
                 // callback: function(chart){
                 // chart.bars1.stacked(true);
                 // chart.bars2.stacked(true);
                 // chart.bars3.stacked(true);
                 // chart.update();
                 // }
             }

         };
     }

     getYOYBarChartProperties= (response, chartObject)=> {
         var toggleType = $('#toggle_container .toggleActive').attr('type') === '$' ? '$' : '';
         var chart = {
             type: 'multiBarChart',
             wrapLabels: false,
             showLabels: true,
             staggerLabels: true,
             reduceXTicks: false,
             useVoronoi: true,
             clipEdge: true,
             useInteractiveGuideline: true,
             area: false,
             showControls: false,
             showValues: true,
             height: 390,
             noData: "No Data Available",
             margin: {
                 top: 30,
                 right: 10,
                 bottom: 50,
                 left: 50
             },
             color: this.yoyColors,
             showTotalInTooltip: true,
             duration: 500,
             stacked: false,
             valueFormat: function (d) {
                 return d3.format('d')(d);
             },
             x: function (d) {
                 if (typeof d !== 'undefined' && d !== null) {
                     return d['x1'];
                 }
             },
             y: function (d) {
                 if (typeof d !== 'undefined' && d !== null) {
                     return d['y'];
                 }
             },
             xAxis: {
                 orient: 'bottom',
                 tickFormat: (d)=> {
                     // return d3.time.format('%m-%d')(new Date(d));
                     return this.unique_x_axis[d];
                 },
                 ticks: 10,
                 tickPadding: 10,
                 // rotateLabels: -45
             },
             yAxis: {
                 showMaxMin: false,
                 ticks: 10,
                 tickFormat: function (d) {
                     if (toggleType === '$') {
                         return toggleType + ' ' + formatYAxis(d);
                     } else {
                         return toggleType + ' ' + formatYAxis(d);
                     }
                 }
             },
             // callback: function(chart){
             //     if (chart && chart.interactiveLayer) {
             //     }
             // },
             interactiveLayer: {
                 tooltip: {
                     contentGenerator: (e)=> {
                        var toggleType = $('#toggle_container .toggleActive').attr('type') === '$' ? '$' : '';
                         var rows = '';
                         var totalRows = '';
                         var totalsKey = '';
                         var bodyRows = '';
                         var totals = 0;
                         var uniqueCategory = [];
                         var column = 'Policies';
                         if (toggleType === '$') {
                             column = 'Amount';
                         }
                         var tot = '';
                         rows = '<tr><td style=\'padding-right: 12px;font-weight:bold\'> Category/Sub Category </td>';
                         e.series.forEach((ele, index) =>{
                             if (ele.key === 'TOTAL') {
                             } else {
                                 // if (ele.key === 'Goal') {
                                 //     toggleType === '$' ? column = 'GoalAmount' : column = 'GoalPolicies';
                                 // } else {
                                 //     toggleType === '$' ? column = 'Amount' : column = 'Policies';
                                 // }
                                 totalsKey = 'TOTALS';
                                 rows += '<td class=\'x-value\' style=\'padding-right: 12px;font-weight:bold\'><span class=\'tooltipLegend\' style=\'background:' + ele.color + '\'></span>' + ele.key + '</td>'
                                 if (ele.data.data) {
                                     ele.data.data.forEach((ele1, index) => {
                                         let obj = uniqueCategory.findIndex(o => o[ele1.Category]);
                                         if (obj <= -1) {
                                             if(ele1[column]){
                                                 uniqueCategory.push({
                                                     [ele1.Category]: [{ [ele.key]: ele1[column] ? ele1[column] : 0 }]
                                                 });
                                             }
                                         } else {
                                             if(ele1[column]){
                                                 uniqueCategory[obj][ele1.Category].push({ [ele.key]: ele1[column] ? ele1[column] : 0 })
                                             }
                                         }
                                         if (ele.key === 'Goal') {
                                             totals += ele1[column];
                                         }
                                     })
                                 }
                                 if (ele.key === 'Goal') {
                                     tot += '<td> <span class=\'tooltipLegend\' > </span>' + toggleType + ' ' + d3.format(',')(totals) + '</td>'
                                 } else {
                                     tot += '<td> <span class=\'tooltipLegend\' > </span>' + toggleType + ' ' + d3.format(',')(ele.value) + '</td>'
                                 }
                             }
                         });

                         for (const [key, value] of Object.entries(uniqueCategory)) {
                             if (Object.keys(value) && Object.keys(value)[0] !== "null") {
                                 bodyRows += '<tr> <td>' + Object.keys(value) + '</td>';
                                 let keyValue: any= Object.keys(value);
                                 var arr = value[keyValue];
                                 e.series.forEach(function (ele, index) {
                                     if (ele.key === 'TOTAL') {
                                     } else {
                                         var year = arr.find(eleme => eleme[ele.key])
                                         if (year) {
                                             bodyRows += '<td ><span class=\'tooltipLegend\' > </span>' + toggleType + d3.format(',')(year[ele.key]) + '</td>'
                                         } else {
                                             bodyRows += '<td ><span class=\'tooltipLegend\' > </span> ' + toggleType + '0</td>'
                                         }
                                     }
                                 })
                                 bodyRows += '</tr>'
                             }
                         }
                         rows += '</tr>';
                         totalRows = totalRows +
                             '<tr style="background: #1ABB9A;">' +
                             '<td></td>' +
                             '<td></td>' +
                             '<td></td>' +
                             '<td></td>' +
                             '<td></td>' +
                             '</tr>' +
                             '<tr >' +
                             '<td class=\'x-value\' >' + totalsKey + '</td>' + tot +
                             '</tr>';

                         var header = '<thead></thead>';

                         return '<span class=\'tooltipHeader\'>' + e.value + '</span>' +
                             '<table>' +
                             header +
                             '<tbody>' +
                             rows +
                             bodyRows +
                             totalRows +
                             '</tbody>' +
                             '</table>';
                             // '<span style="font-style:italic: text-align: left;font-weight:bold;"> <sup>*</sup> Goal based on previous year performance</span>';
                     }
                 }
             }
         };
         chart['width']= $(window).width() >= 1024 ? 17.2 * (window.innerWidth * 0.05) : window.innerWidth;
         //chart['width']= 15 * (window.innerWidth * 0.05);
         return {
             chart: chart
         };
     }

     getLineChartProperties= (response, chartObject) =>{
        this.updatedData = [];
        var colors = ['#4f7393', '#a8da9a', '#eec54c'];
        var toggleType = $('#toggle_container .toggleActive').attr('type') === '$' ? '$' : '';
        if(this.activeTabId === 5){
            toggleType = $('#toggleReport .toggleActive').attr('type') === '$' ? '$' : '';
        }
        // var yAxisLabel = toggleType === '$' ? 'Sale ($)' : 'Policy (#)';
        return  {
            chart: {
                wrapLabels: false,
                type: 'lineChart',
                staggerLabels: true,
                height: 500,
                showControls: true,
                showValues: true,
                showLegend: true,
                noData: 'No Data Available',
                margin: {
                    top: 10,
                    right: 40,
                    bottom: 85,
                    left: 60
                },
                valueFormat: function (d) {
                    return d3.format('d')(d);
                },
                tooltips: true,
                legendPosition: "right",
                x: (d)=> {
                    if (typeof d !== 'undefined' && d !== null) {
                        return +d.x;
                    }
                },
                y: (d) => {
                    if (typeof d !== 'undefined' && d !== null) {
                        return this.intVal(d.y);
                    }
                },
                // legend: {
                // dispatch: {
                // legendClick: function(e) {
                // // $scope.openModal($scope.fromDate, $scope.tDate, e.key);
                // },
                // stateChange: function(e) {}
                // },
                // updateState: false

                // },
                tooltip: {
                    contentGenerator: function (d) {
                        var date;
                        if (d.point.z === '') {
                            // date = .dateValues[d.point.x];
                        } else {
                            date = d.point.z;
                        }
                        var tablecontent = '';
                        tablecontent = '<table id="tooltip">';
                        tablecontent += '<tr><td > Month: </td><td><span  style="color:#2C5E91">' + date + '</span></td></tr>';
                        // tablecontent += "<tr><th> Plan </th>";
                        // tablecontent += "<th> Policy/Sale </th></tr>";
                        tablecontent += "<tr><td>" + d.series[0].key + "</td>";
                        if ($('#toggleReport .toggleActive').attr('type') === '$') {
                            tablecontent += "<td> $ " + d3.format(',f')(d.point.y) + "</td></tr>";
                        } else {
                            tablecontent += "<td> " + d3.format(',f')(d.point.y) + "</td></tr>";
                        }

                        return tablecontent;
                    }
                },
                xAxis: {
                    rotateLabels: -45,
                    axisLabel: 'Month',
                    //ticks: 12,
                    showMaxMin: false,
                    tickValues: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
                    orient: 'bottom',
                    tickFormat: function (categories) {
                        var cat = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]
                        return cat[categories];
                    }
                },
                yAxis: {
                    axisLabel: '',
                    tickFormat: function (d) {
                        if (toggleType === '$') {
                            return toggleType + ' ' + d3.format('.3s')(d);
                        } else {
                            return toggleType + ' ' + d3.format('.3s')(d);
                        }
                    }// axisLabelDistance: '20'
                }
            },
            caption: {
                enable: '',
                html: ''
            }
        };
    }

    getLineYOYComparisonChartProperties = (response, chartObject) =>{
        return {
            chart: {
                wrapLabels: false,
                type: 'lineChart',
                staggerLabels: true,
                height: 300,
                width: '',
                showControls: true,
                showValues: true,
                showLegend: true,
                noData: 'No Data Available',
                margin: {
                    top: 30,
                    right: 75,
                    bottom: 65,
                    left: 70
                },
                valueFormat: function (d) {
                    return d3.format('d')(d);
                },
                tooltips: true,
                legendPosition: "right",
                x: (d)=> {
                    if (typeof d !== 'undefined' && d !== null) {
                        return +d.x;
                    }
                },
                y: (d) =>{
                    if (typeof d !== 'undefined' && d !== null) {
                        return this.intVal(d.y);
                    }
                },
                tooltip: {
                    contentGenerator: (d) =>{
                        var date;
                        if (d.point.z === '') {
                            //date = $scope.dateValues[d.point.x];
                        } else {
                            date = d.point.z;
                        }
                        var tablecontent = '';
                        tablecontent = '<table id="tooltip">';
                        tablecontent += '<tr><td > Month: </td><td><span  style="color:#2C5E91">' + date + '</span></td></tr>';
                        tablecontent += "<tr><td>" + d.series[0].key + "</td>";
                        if ($('#performanceChartToggle .active').attr('type') === '$') {
                            tablecontent += "<td> $ " + d3.format(',f')(d.point.y) + "</td></tr>";
                        } else {
                            tablecontent += "<td> " + d3.format(',f')(d.point.y) + "</td></tr>";
                        }

                        return tablecontent;
                    }
                },
                xAxis: {
                    rotateLabels: -45,
                    axisLabel: '',
                    //ticks: 12,
                    showMaxMin: false,
                    tickValues: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
                    orient: 'bottom',
                    tickFormat: function (categories) {
                        var cat = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]
                        return cat[categories];
                    }
                },
                yAxis: {
                    // axisLabel: yAxisLabel,
                    tickFormat: function (d) {
                        var finalValue = '';
                        if (d > 999) {
                            if ($('#performanceChartToggle .active').attr('type') === '$') {
                                finalValue = '$ ' + d3.format('.3s')(d);
                            } else {
                                finalValue = d3.format('.3s')(d);
                            }
                        } else {
                            finalValue = d3.format(',f')(d);
                        }
                        return finalValue;
                        //return d3.format(',f')(d);
                    }// axisLabelDistance: '20'
                },

                callback: (chart) =>{
                    $('#showTooltip').hide();
                    this.commonService.showLoader();
                    chart.lines.dispatch.on('elementClick', (e)=> {
                        let performanceToggleType = $('#performanceChartToggle .active').attr('type') === '$' ? '$' : '#';
                        let selectedMonth = e.point['z'].toLowerCase();
                        // var params = this.commonService.getParameters();
                        var params = popupParameter;
                        var months = ["jan", "feb", "mar", "apr", "may", "jun", "jul", "aug", "sep", "oct", "nov", "dec"];
                        selectedMonth = months.indexOf(selectedMonth);
                        params['mthNumber'] = selectedMonth + 1;
                        // if($scope.performanceTapYesNo === false){
                        //     api = 'getProductsComparisionTap';
                        //     params.param5 = details.crmId;
                        // }
                        this.commonService.getProductComparison(params).subscribe(res=>{
                            let month = e.point['z'].toLowerCase();;
                            this.showYoyTooltip.next([res,month]);
                        });
                    });
                }
            },
            caption: {
                enable: '',
                html: ''
            }
        }
    }

    intVal = (i:any) => {
        let no = i;
        return typeof no === 'string' ?
            i.replace(/[\$,]/g, '') * 1 :
            typeof i === 'number' ?
                i : 0;
    };

     transformToTree(arr) {
         var nodes = {};
         return arr.filter(function (obj) {
             obj.size = $('#toggle .toggleActive').attr('type') === '$' ? obj.sale : obj.policies;
             var id = obj['id'],
                 parentId = obj['parentId'];

             nodes[id] = _.defaults(obj, nodes[id], { children: [] });
             parentId && (nodes[parentId] = (nodes[parentId] || { children: [] }))['children'].push(obj);

             return !parentId;
         });
     }

     // program to check leap year
     checkLeapYear(year) {
         //three conditions to find out the leap year
         if ((0 == year % 4) && (0 != year % 100) || (0 == year % 400)) {
             return true;
         } else {
             return false;
         }
     }

    getModifiedData(chartType, rows) {
        let userInfo = JSON.parse(localStorage.getItem('userInfo'));
        let activeTabId = this.localStorageService.get('activeTabId');
        let userRole = userInfo['role'];
        let isTAPRequired = userInfo['isTAPRequired'];
        let monthArray =  ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
        if( chartType === 'yoyPerformanceLinechart'){
            var response1 = {};
            var result = [];
            var host = [];
            var color = ['rgb(152, 223, 138)', 'rgb(118, 145, 190)', 'rgb(101, 189, 177)', 'rgb(31, 119, 180)', 'rgb(255, 187, 120)'];
            var categories = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
            rows.forEach(function (ele, index) {
                var keyName = ele.Plan || ele.Years;
                host[index] = [];
                for (let i = 0; i < categories.length; i++) {
                    host[index].push({
                        x: i,
                        y: ele[categories[i]],
                        z: categories[i]
                    });
                }
                result.push({
                    values: host[index], //values - represents the array of {x,y} data points
                    key: keyName, //key  - the name of the series.
                    color: color[index], //color - optional: choose your own line color.
                    strokeWidth: 2,
                    classed: 'dashed'
                });
            });
            response1 = {
                data: result
            };
            return response1;
        } else if (chartType === 'lineChart') {
            var response1 = {};
            var result = [];
            var host = [];
            var color = ['rgb(152, 223, 138)', 'rgb(118, 145, 190)', 'rgb(101, 189, 177)', 'rgb(31, 119, 180)', 'rgb(255, 187, 120)'];
            if(rows.data) {
                rows.data.forEach(function (ele, index) {
                    var keyName = ele.Plan;
                    host[index] = [];
                    if (index === 0) {
                        host.push(ele.Plan);
                    } else {
                        for (let i = 0; i < monthArray.length; i++) {
                            host[index].push({
                                x: i,
                                y: ele[ monthArray[i]],
                                z: monthArray[i]
                            });
                        }
                    }
                    result.push({
                        values: host[index], //values - represents the array of {x,y} data points
                        key: keyName, //key  - the name of the series.
                        color: color[index], //color - optional: choose your own line color.
                        strokeWidth: 2,
                        classed: 'dashed'
                    });
                });
            }
            response1 = {
                data: result
            };
            return response1;
        } else if (chartType === '90DayYOYLineChart') {
             var type = 'line';
             var response2 = [];
             var response3 = {};
             var currentYear = new Date().getFullYear();
             var selector = $('#toggle .toggleActive').attr('type') === '$' ? '$' : '#';
             var column = 'Policies';
             if (selector === '$') {
                 column = 'Amount'
             }
             var goalData = [];
             var isLeapYear = false;
             var leapYear = '';
             var feb29Data = 0;
             for (let k = 0; k < rows.length; k++) {
                 isLeapYear = this.checkLeapYear(rows[k].key);
                 leapYear = rows[k].key;
                 if (isLeapYear) k = rows.length;
             }
             for (let i = 0; i < rows.length; i++) {
                 var key: any = 'Date';
                 var dataByDateObj = JSON.parse(JSON.stringify(rows[i].values)).reduce(function (rv, x) {
                     let v = key instanceof Function ? key(x) : x[key];
                     let el = rv.find((r) => r && r.key === v);
                     if (el) {
                         el.total += x[column];
                         el.values.push(x);
                     } else {
                         rv.push({
                             key: v,
                             values: [x],
                             total: x[column]
                         });
                     }
                     return rv;
                 }, []);
                 rows[i]['values'] = [];
                 rows[i]['values'].push(dataByDateObj);
                 var uniqueDates = rows[i]['values'][0]
                 var values_array = [];
                 var month = ['', 'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
                 var index = 0;
                     for (let j = 0; j < uniqueDates.length; j++) {
                         var date1 = uniqueDates[j].key.split('-');
                         var date = Number(date1[1])+ '-' + date1[2];
                         if (leapYear) {
                             if (date == '2-29') {
                                 feb29Data = uniqueDates[j].total;
                             }
                         }
                         var obj = {
                             'x': date,
                             'x1': index,
                             'y': uniqueDates[j].total,
                             'data': uniqueDates[j].values,
                             // index: j,
                             seriesIndex: i,
                             series: i
                         };
                         values_array.push(obj);
                         index++;
                         if (isLeapYear && leapYear != rows[i].key) {
                             if(i == rows.length - 1 && date == '2-28'){
                                 obj = {
                                     'x': '2-29',
                                     'x1': index,
                                     'y': feb29Data,
                                     'data': [],
                                     // index: j,
                                     seriesIndex: i,
                                     series: i
                                 };
                                 values_array.push(obj);
                                 index++;
                             } else if (date == '2-28' || date == '2-29') {
                                 obj = {
                                     'x': '2-29',
                                     'x1': index,
                                     'y': 0,
                                     'data': [],
                                     // index: j,
                                     seriesIndex: i,
                                     series: i
                                 };
                                 values_array.push(obj);
                                 index++;
                             }
                         }
                     }
                     var data1 = {};
                     if (i == rows.length - 1) {
                         data1 = {
                             key: 'Goal',
                             values: values_array,
                             type: 'line',
                             yAxis: 1,
                             color: '#BA3636',
                             // classed: 'goal-dashed'
                             classed: 'goal-stroke'
                         };
                         this.unique_x_axis = _.uniq(_.pluck(_.flatten(values_array), 'x'));
                     } else {
                         data1 = {
                             key: rows[i].key,
                             values: values_array,
                             yAxis: 1,
                             type: 'line',
                             classed: 'line-stroke'
                         };
                     }
                     response2.push(data1);
              }
             // console.log("Chart Data", unique_x_axis)
             response3 = {
                 data: response2
             };
             return response3;
         } else if (chartType === 'YOYLineChart' || chartType === 'YOYBarChart') {
             var type = 'line';
             if(chartType === 'YOYBarChart'){
                 type = 'bar'
             }else {
                 type = 'line'
             }
             var response2 = [];
             var response3 = {};
             var selector = $('#toggle .toggleActive').attr('type') === '$' ? '$' : '#';
             var column = 'Policies';
             if (selector === '$') {
                 column = 'Amount'
             }
             // [x.Months, x['SAP Amount'], x['SAP Policies'], x.category]
             for (let i = 0; i < rows.length; i++) {
                 var key:any = 'Months';
                 var data = JSON.parse(JSON.stringify(rows[i].values)).reduce(function (rv, x) {
                     let v = key instanceof Function ? key(x) : x[key];
                     let el = rv.find((r) => r && r.key === v);
                     if (el) {
                         el.total += x[column];
                         el.values.push(x);
                     } else {
                         rv.push({
                             key: v,
                             values: [x],
                             total: x[column]
                         });
                     }
                     return rv;
                 }, []);
                 rows[i]['values'] = [];
                 rows[i]['values'].push(data);
                 var uniqueMonth = rows[i]['values'][0];
                 let month = [];
                 if(this.isWeeklyChart){
                     month = ['', 'Q1', 'Q2', 'Q3', 'Q4'];
                 } else {
                     month = ['', 'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
                 }
                 var values_array = [];
                 for (let j = 0; j < uniqueMonth.length; j++) {
                     let obj = {
                         'x': month[uniqueMonth[j].key],
                         'x1': j,
                         'y': uniqueMonth[j].total,
                         'data': uniqueMonth[j].values,
                         // index: j,
                         seriesIndex: i,
                         series: i
                     };
                     values_array.push(obj);
                 }
                 var data1 = {};
                 if (i == rows.length - 1) {
                     data1 = {
                         key: 'Goal',
                         values: values_array,
                         type: type,
                         yAxis: 1,
                         color: '#BA3636',
                         classed: 'goal-stroke'
                         // classed: 'goal-dashed'
                     };
                     this.unique_x_axis = _.uniq(_.pluck(_.flatten(values_array), 'x'));
                 } else {
                     data1 = {
                         key: rows[i].key,
                         values: values_array,
                         yAxis: 1,
                         type: type,
                         classed: 'line-stroke'
                     };
                 }
                 response2.push(data1);
             }
             response3 = {
                 data: response2
             };
             // unique_x_axis = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]
             return response3; //JSON.stringify(response2);

         } else if (chartType === 'stackedAreaChart') {
             var response2 = [];
             var response3 = {};
             var selector = $('#toggle .toggleActive').attr('type') === '$' ? 'x1' : 'x2';
             if (isTAPRequired === 'yes' && this.sapOrTapToggle.byProductTapValue !== 'sap') {
                if (selector === 'x1') {
                    selector = 'x3';
                } else if (selector === 'x2') {
                    selector = 'x4';
                }
             }
             // Get Unique Plan Codes
             var uniq_x1 = _.uniq(_.pluck(_.flatten(rows), 'x'));
             var uniq_x2 = _.uniq(_.pluck(_.flatten(rows), 'y'));

             for (let i = 0; i < uniq_x1.length; i++) {
                 var res1;
                 //Get all records from rows for x1 value
                 res1 = _.where(rows, {
                     'x': uniq_x1[i]
                 });
                 var x2_data_array = [];
                 var values_array = [];
                 for (let k = 0; k < uniq_x2.length; k++) {
                     for (let j = 0; j < res1.length; j++) {
                         if (uniq_x2[k] === res1[j].y) {
                             x2_data_array = [];
                             x2_data_array.push(res1[j].y, res1[j][selector], res1[j].y1, uniq_x2);
                             values_array.push(x2_data_array);
                             k++;
                         }
                     }

                     if (uniq_x2[k]) {
                         x2_data_array = [];
                         x2_data_array.push(uniq_x2[k], 0, '', uniq_x2);
                         values_array.push(x2_data_array);
                     }
                 }
                 let data = {
                     key: uniq_x1[i],
                     values: values_array
                 };
                 response2.push(data);
             }
             response3 = {
                 data: response2
             };
             return response3; //JSON.stringify(response2);

         } else if (chartType === 'multiBarChart') {
             var multiBarChartSelector = $('#toggle .toggleActive').attr('type') === '$' ? 'x1' : 'y2';
             if (isTAPRequired === 'yes' && this.sapOrTapToggle.byCarrierTapValue !== 'sap') {
                 if (multiBarChartSelector === 'x1') {
                     multiBarChartSelector = 'x2';
                 } else if (multiBarChartSelector === 'y2') {
                     multiBarChartSelector = 'y3';
                 }
             }
             var multiBarChartUniq_x1 = _.uniq(_.pluck(_.flatten(rows), 'x'));
             var filterArray = [];
             var multiChartObj;
             multiBarChartUniq_x1.forEach(function (ele) {
                 multiChartObj = {
                     key: ele,
                     values: []
                 };
                 rows.filter(function (element) {
                     if (element.x === ele) {
                         multiChartObj.values.push({
                             x: element.y,
                             x1: element.y3,
                             y: element[multiBarChartSelector]
                         });
                     }
                     return element.x === ele;
                 });
                 filterArray.push(multiChartObj);
             });
             return { data: filterArray };
         } else if (chartType === 'linePlusBarChart') {
             var linePlusBarChartData = [{
                 'key': '$',
                 'bar': true,
                 'yAxis': 1,
                 'color': 'rgb(31, 119, 180)',
                 'values': []
             }, {
                 'key': '#',
                 'color': 'rgb(255, 127, 14)',
                 'yAxis': 2,
                 'values': []
             }];
             rows.forEach(function (ele) {
                 linePlusBarChartData[0].values.push({
                     'x': ele.x,
                     'y': ele.y1,
                     'xLabel': ele.xLabel
                 });
             });
             rows.forEach(function (ele) {
                 linePlusBarChartData[1].values.push({
                     'x': ele.x,
                     'y': ele.y2,
                     'xLabel': ele.xLabel
                 });
             });
             return {
                 data: linePlusBarChartData
             };
         } else if (chartType === 'pieChart') {
            let toggleType = '#';
            toggleType = $('#businessByToggleBtn .active').attr('type') === '#' ? '#':'$';
             rows.forEach(function (row) {
                row.y = toggleType == '$'? row.Sales: row.Policy;
                if($('#businessByToggleBtn .active').attr('type') === 'applications'){
                row.y = row.Policy;
                }
                if($('#businessByToggleBtn .active').attr('type') === 'commission'){
                    row.y = row.Commission;
                }
             });
             return {
                 data: rows
             };
         } else if (chartType === 'sunburstChart') {
             return {
                 data: [{
                     'name': this.client_name,
                     'children': this.transformToTree(rows)
                 }]
             };
         } else if (chartType === 'map') {
             var salepolicytoggle = '';
             if(this.activeTabId == 5) {
                 salepolicytoggle = $('#toggleReport_container .toggleActive').attr('type') === '$' ? '$' : '#';
             } else {
                 salepolicytoggle = $('#toggle_container .toggleActive').attr('type') === '$' ? '$' : '#';
             }
             var sapOrTapToggle = this.sapOrTapToggle.mapDataTapValue;
             var role = userRole;
             var response = {};
             for (let i = 0; i < rows.length; i++) {
                 var state = rows[i].x1;
                 var fillKey = 'defaultFill';
                 if (salepolicytoggle === '$' && sapOrTapToggle === 'sap') {
                     if (role === "Manager") {
                         if (rows[i].y1 < 50000) {
                             fillKey = '0 - 50,000'
                         } else if (rows[i].y1 >= 50000 && rows[i].y1 < 100000) {
                             fillKey = '50,000 - 1,00,000'
                         } else if (rows[i].y1 >= 100000 && rows[i].y1 < 200000) {
                             fillKey = '1,00,000 - 2,00,000'
                         } else if (rows[i].y1 >= 200000 && rows[i].y1 < 300000) {
                             fillKey = '2,00,000 - 3,00,000'
                         } else if (rows[i].y1 >= 300000) {
                             fillKey = '> 3,00,000'
                         }
                     } else {
                         if (rows[i].y1 < 2500) {
                             fillKey = '0 - 2,500'
                         } else if (rows[i].y1 >= 2500 && rows[i].y1 < 5000) {
                             fillKey = '2,500 - 5,000'
                         } else if (rows[i].y1 >= 5000 && rows[i].y1 < 7500) {
                             fillKey = '5,000 - 7,500'
                         } else if (rows[i].y1 >= 7500 && rows[i].y1 < 12000) {
                             fillKey = '7,500 - 12,000'
                         } else if (rows[i].y1 >= 12000) {
                             fillKey = '> 12,000'
                         }
                     }
                 } else if (salepolicytoggle === '#' && sapOrTapToggle === 'sap') {
                     if (role === "Manager") {
                         if (rows[i].y2 < 500) {
                             fillKey = '0 - 500'
                         } else if (rows[i].y2 >= 500 && rows[i].y2 < 1000) {
                             fillKey = '500 - 1,000'
                         } else if (rows[i].y2 >= 1000 && rows[i].y2 < 5000) {
                             fillKey = '1,000 - 5,000'
                         } else if (rows[i].y2 >= 5000 && rows[i].y2 < 10000) {
                             fillKey = '5,000 - 10,000'
                         } else if (rows[i].y2 >= 10000) {
                             fillKey = '> 10,000'
                         }
                     } else {
                         if (rows[i].y2 < 100) {
                             fillKey = '0 - 100';
                         } else if (rows[i].y2 >= 100 && rows[i].y2 < 200) {
                             fillKey = '100 - 200';
                         } else if (rows[i].y2 >= 200 && rows[i].y2 < 300) {
                             fillKey = '200 - 300';
                         } else if (rows[i].y2 >= 300 && rows[i].y2 < 500) {
                             fillKey = '300 - 500';
                         } else if (rows[i].y2 >= 500) {
                             fillKey = '> 5000';
                         }
                     }
                 } else if (salepolicytoggle === '$' && sapOrTapToggle === 'tap') {
                     if (role === "Manager") {
                         if (rows[i].y3 < 50000) {
                             fillKey = '0 - 50,000'
                         } else if (rows[i].y3 >= 50000 && rows[i].y3 < 100000) {
                             fillKey = '50,000 - 1,00,000'
                         } else if (rows[i].y3 >= 100000 && rows[i].y3 < 200000) {
                             fillKey = '1,00,000 - 2,00,000'
                         } else if (rows[i].y3 >= 200000 && rows[i].y3 < 300000) {
                             fillKey = '2,00,000 - 3,00,000'
                         } else if (rows[i].y3 >= 300000) {
                             fillKey = '> 3,00,000'
                         }
                     } else {
                         if (rows[i].y1 < 2500) {
                             fillKey = '0 - 2,500'
                         } else if (rows[i].y1 >= 2500 && rows[i].y1 < 5000) {
                             fillKey = '2,500 - 5,000'
                         } else if (rows[i].y1 >= 5000 && rows[i].y1 < 7500) {
                             fillKey = '5,000 - 7,500'
                         } else if (rows[i].y1 >= 7500 && rows[i].y1 < 12000) {
                             fillKey = '7,500 - 12,000'
                         } else if (rows[i].y1 >= 12000) {
                             fillKey = '> 12,000'
                         }
                     }
                 } else {
                     if (role === "Manager") {
                         if (rows[i].y4 < 500) {
                             fillKey = '0 - 500'
                         } else if (rows[i].y4 >= 500 && rows[i].y4 < 1000) {
                             fillKey = '500 - 1,000'
                         } else if (rows[i].y4 >= 1000 && rows[i].y4 < 5000) {
                             fillKey = '1,000 - 5,000'
                         } else if (rows[i].y4 >= 5000 && rows[i].y4 < 10000) {
                             fillKey = '5,000 - 10,000'
                         } else if (rows[i].y4 >= 10000) {
                             fillKey = '> 10,000'
                         }
                     } else {
                         if (rows[i].y2 < 100) {
                             fillKey = '0 - 100';
                         } else if (rows[i].y2 >= 100 && rows[i].y2 < 200) {
                             fillKey = '100 - 200';
                         } else if (rows[i].y2 >= 200 && rows[i].y2 < 300) {
                             fillKey = '200 - 300';
                         } else if (rows[i].y2 >= 300 && rows[i].y2 < 500) {
                             fillKey = '300 - 500';
                         } else if (rows[i].y2 >= 500) {
                             fillKey = '> 500';
                         }
                     }
                 }
                 response[state] = {
                     "fillKey": fillKey,
                     "active_policies": rows[i].y2.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                     "earned_premiums": rows[i].y1.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                     "tap_active_policies": rows[i].y4 ? rows[i].y4.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : rows[i].y4,
                     "tap_earned_premiums": rows[i].y3 ? rows[i].y3.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : rows[i].y3
                 }
             }
             return {
                 data: response
             };
         }
     }
 }

function formatYAxis(d: any){
    if(d3.formatPrefix(d).symbol == "m" && d >= -1) {
        return d;
    } if(d <999) {
        return d3.format("d")(d);
    } else {
        return d3.format(".3s")(d);
    }
}
