import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../../src/environments/environment';
import { ApiConstant } from '../constants/api-constant';
import { BehaviorSubject, of, Subject} from 'rxjs';
import { LocalstorageService } from './localstorage.service';
import { CommonConstant } from '../constants/common-constant';
import { NgxSpinnerService } from 'ngx-spinner';
import {configJson, DashboardConfigService} from './dashboard-config.service';
import { catchError, map } from 'rxjs/operators';

// interface ICache { [ key: string ]: BehaviorSubject<any>; }
// type serializable = object | Object;

@Injectable({
  providedIn: 'root'
})
export class CommonService {
  public static showLogoutPage: boolean = false;
  activeSubTabData: any = {};
  activeTabData: any = {};
  private baseUrl = environment.baseURL;
  activeTabId = new Subject<any>();
  tabValue = new BehaviorSubject(this.tabData);
  checkSelectorLoaded = new Subject();
  activeSubTabValue = new BehaviorSubject(this.activeSubTabData);
  activeTabValue = new BehaviorSubject(this.activeTabData);
  private sapAndTapData : Object = CommonConstant.sapOrTapToggle;
  private selectorData: Array<Object> = [];
  private httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    Response: 'text' as 'json'
  };
  activeAgentTab: any;
  cache: any;
  productList : Array<object> = [];
  resetAgencyChart = new Subject<any>();
  updateYOYChart = new Subject<any>();
  updateStateMapChart = new Subject<any>();
  infoLablesData = new Subject<[]>();
  updateTableData = new Subject<[]>();
  updateAlertsData = new Subject<[]>();
  updateReportsData = new Subject<[]>();
  updateAgentsData = new Subject<[]>();
  logoPath: any;
  lobVal: any;
  allPolicyStatus: any;
  q1Title:any;
  q2Title:any;
  SSOLogin : string;
  medicareAdvantageSaleType: string = '';
  clientName: string = '';
  sapOrTapToggle = {
    byProductTapValue: 'sap',
    byCarrierTapValue: 'sap',
    mapDataTapValue: 'sap',
    agentsTabTapValue: 'sap',
    analyticsTabTapValue: 'sap',
    reportsTabTapValue: 'sap',
    stackedPlacedToggleVal: 'submitted',
    multiBarPlacedToggleVal: 'submitted',
    mapPlacedToggleVal: 'submitted'
  }
  tableSearchObj: any = [];
  notificationControl;
  RTSCarrierList:any;
  constructor(private http: HttpClient,
    private localStorageService: LocalstorageService,
    private spinner: NgxSpinnerService,
    private dashboardConfigService: DashboardConfigService
    ) {
    // this.cache = Object.create(null);
  }
  incentive_to_date:string = '';
  incentive_from_date:string = '';

  getisPlacedToggleFlag(){
    let userInfo = JSON.parse(localStorage.getItem('userInfo'));
    return userInfo['isPlacedToggleRequired'];
  }

  getMedicareAdvantageFlag(){
    let userInfo = JSON.parse(localStorage.getItem('userInfo'));
    return userInfo['medicareAdvantageSaleType']
  }

  resetSelectorPosition(){
    setTimeout(() => {
      // let a = $('.row .selectorBlock');
      // let temp = [];
      // for (let e of a){
      //   if(e.style.display != 'none' && !['toggle_container','resetBtn_container','toggleAgents_container'].includes(e.id)) temp.push(e)
      // }
      // // setTimeout(()=>{
      //   if(temp.length > 6){
          // $('#toggleAgents_container, #resetBtn_container, #toggle_container').css('margin-top', '-44px');
          $('#resetBtn_container').css('margin-top', '-44px');
          $('#toggleAgents_container').css('margin-top', '-44px');
          $('#toggle_container').css('margin-top', '-44px');
        // } else {
        //   // $('#toggleAgents_container, #resetBtn_container, #toggle_container').css('margin-top', '0px');
        //   $('#resetBtn_container').css('margin-top', '0px');
        //   $('#toggleAgents_container').css('margin-top', '0px');
        //   $('#toggle_container').css('margin-top', '0px');
        // }
      // }, 100)
    }, 200)
  }

  setInfolabelData(value) {
    this.infoLablesData.next(value);
  }

  setIsSelectorLoaded() {
    this.checkSelectorLoaded.next(true);
  }

  set tabData(value) {
    this.tabValue.next(value); // this will make sure to tell every subscriber about the change.
    localStorage.setItem('userTabInfo', value);
  }

  get tabData() {
    return JSON.parse(localStorage.getItem('userTabInfo'));
  }

  // setTabDetails<T extends serializable>( key: string, value: T ): BehaviorSubject<T> {
  //   localStorage.setItem(key, JSON.stringify(value));
  //   if ( this.cache[ key ] ) {
  //     this.cache[ key ].next( value );
  //     return this.cache[ key ];
  //   }
  //   return this.cache[ key ] = new BehaviorSubject( value );
  // }

  // getTabDetails<T extends serializable>( key: string ): BehaviorSubject<T> {
  //   if ( this.cache[ key ] )
  //     return this.cache[ key ];
  //   else
  //     return this.cache[ key ] = new BehaviorSubject( JSON.parse( localStorage.getItem( key ) ) );
  // }

  // removeItem ( key: string ) {
  //   localStorage.removeItem( key );
  //   if ( this.cache[ key ] )
  //     this.cache[ key ].next( undefined );
  // }
 
  async setActiveTabData(url) {
    let parentRoute = url.split('/');
    let activeTabObj = [], childRoute = [] ;
    !configJson ? await this.dashboardConfigService.getDashboardConfig():'';
    this.SSOLogin = configJson.ssoLogin;
      if(this.SSOLogin === 'yes'){ // extra email id query parameter
        this.spinner.show();
        if(this.tabData && this.tabData.length) {
          parentRoute.forEach((childroute, routeIndex) => {
            if (childroute!='' && childroute !=null && childroute != 'app') {
              childroute = childroute.includes('?') == true ? childroute.split('?')[0]:childroute;
              activeTabObj = this.tabData.filter(ele => ele.tab_name.replace(/\s/g, '').toLowerCase() === childroute)
              if (activeTabObj.length !=0) {
                // this.activeTabData.next(tab);
                this.localStorageService.set('activeTabId', activeTabObj[0].tab_id);
                if(routeIndex+1 == parentRoute.length){
                  this.localStorageService.set('activeSubTab', {});
                } else {
                  childRoute = parentRoute.slice(routeIndex+1, parentRoute.length)
                  this.setActiveSubTabData(childRoute, activeTabObj);
                }
              }
            }
          });
        } else {
              this.getTabData({}).subscribe(res => {
                this.tabData = res;
                parentRoute.forEach((childroute, routeIndex) => {
                  if (childroute!='' && childroute !=null && childroute != 'app') {
                    childroute = childroute.includes('?') == true ? childroute.split('?')[0]:childroute;
                    activeTabObj = this.tabData.filter(ele => ele.tab_name.replace(/\s/g, '').toLowerCase() === childroute)
                    if (activeTabObj.length !=0) {
                      // this.activeTabData.next(tab);
                      this.localStorageService.set('activeTabId', activeTabObj[0].tab_id);
                      if(routeIndex+1 == parentRoute.length){
                        this.localStorageService.set('activeSubTab', {});
                      } else {
                        childRoute = parentRoute.slice(routeIndex+1, parentRoute.length)
                        this.setActiveSubTabData(childRoute, activeTabObj);
                      }
                    }
                  }
                });
            });
        }
      } else {
        parentRoute.forEach((childroute, routeIndex) => {
          if (childroute!='' && childroute !=null && childroute != 'app') {
            childroute = childroute.includes('?') == true ? childroute.split('?')[0]:childroute;
            activeTabObj = this.tabData.filter(ele => ele.tab_name.replace(/\s/g, '').toLowerCase() === childroute)
            if (activeTabObj.length !=0) {
              // this.activeTabData.next(tab);
              this.localStorageService.set('activeTabId', activeTabObj[0].tab_id);
              if(routeIndex+1 == parentRoute.length){

              } else {
                childRoute = parentRoute.slice(routeIndex+1, parentRoute.length)
                this.setActiveSubTabData(childRoute, activeTabObj);
              }
            }
          }
        });
      }
  }

  

  setActiveSubTabData(childRoute, tabObj){
    childRoute.forEach((childroute) => {
      if (tabObj[0]['subTabs'] && tabObj[0]['subTabs'].length) {
        tabObj[0]['subTabs'].forEach(subtab => {
          if (subtab.sub_tab_name.replace(/\s/g, '').toLowerCase() === childroute) {
            this.localStorageService.set('activeSubTab', { id: subtab.sub_tab_id, api: subtab.api_name });
            this.activeSubTabValue.next({ id: subtab.sub_tab_id, api: subtab.api_name });
          }
        });
      }
    })
 }

  getSelector() {
    return this.selectorData;
  }

  showLoader(): void {
    this.spinner.show();
  }

  hideLoader(): void {
    this.spinner.hide();
  }

  setSelector(selectorName, selectorValue) {
    this.selectorData[selectorName] = selectorValue;
  }

  getSapOrTapToggle(){
    return this.sapAndTapData;
  }

  setSapOrTapToggle(activeTab, name, value) {
    this.sapAndTapData[name] = value;
  }

  //Converts the date to a string 'yyyy-mm-dd'
  convertDate(date) {
    var newDate = new Date(date);
    var year = newDate.getFullYear();
    var month = newDate.getMonth() + 1;
    var day = newDate.getDate();
    return year + '-' + month + '-' + day;
  };

  getActiveAgentTab() {
    return this.activeAgentTab;
  }

  setActiveAgentTab(id){
    this.activeAgentTab = id;
  }

  getLogoPath(){
    return this.logoPath;
  }

  setLogoPath(path){
    this.logoPath = path;
  }

  getNotificationControl(){
    return this.notificationControl;
  }

  setNotificationControl(data){
    this.notificationControl = data;
  }

  getLobVal(){
    return this.lobVal;
  }

  setLobVal(val){
    this.lobVal = val;
  }

  getRTSCarriersList(){
    return this.RTSCarrierList;
  }

  setRTSCarriersList(val){
    this.RTSCarrierList = val;
  }

  getQ1Title(){
    return this.q1Title;
  }

  setQ1Title(val){
    this.q1Title = val;
  }

  getQ2Title(){
    return this.q2Title;
  }

  setQ2Title(val){
    this.q2Title = val;
  }

  getAllPolicyStatus(){
    return this.allPolicyStatus;
  }

  setAllPolicyStatus(val){
    this.allPolicyStatus = val;
  }

  parametersObj: object = {
    "salepolicytoggle": "#",
    'tabId': 1,
    'clientType': "agency",
    'param1': '', //["A. Anthem","AARP","Aetna","Aetna MA","AIG","Am National","Assurity","Banner","BCBS","Capitol","Cigna","Cigna HS","Cigna HS McCunis-Fox","CUL","Foresters","Friday","Gerber","GTL","GW","Humana","IHC","Ill. Mutual","IMG","J Hancock","Kemper","Lincoln Nat.","Medico","Molina","MoO","NACOLAH","Nat. Gen","OneAmerica","Oscar","Pacific Life","Protective","Prudential","SBLI","SDS Dental","Sidecar","Surebridge","Trans Am","UHC","UHOne","UNL","Wash. Nat.","WellCare"], // carriers array values
    'param2': '', //'All', // products values
    'param3': '',//his.convertDate(new Date(new Date().getFullYear(), 0, 1)), // from date
    'param4': '',//this.convertDate(this.now), //to date
    'forYear': '',//this.convertDate(this.now), //year
    'param5': 'All', //123456/123BA3E based on id type ->  (crm/agent)
    'param6': 'All', //: 'Tx'  (on state map chart)
    'param7': 'category', // category/subcategory
    'param8': '',
    'param9': 0, //pending policies subatab
    'param10': '', //in Analytics tab --> high performance/Low Performers/lapsed policies subtab --> policy count
    'param11': 'policy', //policy/premium value :(agent id column clickable)
    'param12': 'All', //medicare advantage product sale type : (product line --> sales type, when user select form product line filter dropdown and select medicare advantage product)
    'attribute1': '', //
    'lob': 'All',
    'policyStatus': '',//["Active","Cancelled","Death","Declined","Free Look","Inactive","Invalid","Issued","Lapse Pending","Lapsed","Not Taken","Pending","Rejected","Replaced","Surrendered","Suspended","Terminated","Unmapped","Withdrawn"],
    'applicationStatus':'',
    'filterDateType': '', //ApiConstant[`filterDate`],
    'mapChartYesNo': true,
    'sapOrTapToggle': this.selectorData[CommonConstant.parameterMappingObj['sapOrTapToggle']],//this.sapAndTapData['sapOrTapToggle'],
    'activeAgentTab': 1,
    'weekMonthToggle': false,
    'renderTableOrChart': false,
    'tapReportToggle': true,
    'contractDateInterval': '',
    'alertRadioBtnValue': ApiConstant[`alertRadioBtnValue`],
    'alertDropDownValue': ApiConstant[`alertDropDownValue`],
    'selectedInfolabalValue': ApiConstant[`selectedInfolabalValue`],
    'missingAgentTab': false,
    'agentMarketingCompany': '',
    'orderBy': '',
    'pageNo': 0,
    'offset': 100,
    'subProduct': '',
    'breadcrumbFilters':'',
    'crmId':'',
    'agencyFilters':'',
    'carrierFilter': ''
  }

  getParameters() {
    !this.parametersObj ? this.parametersObj ={}:'';
    this.parametersObj['tabId'] = this.localStorageService.get('activeTabId');
    this.parametersObj['filterDateType'] = this.selectorData[CommonConstant.parameterMappingObj['filterDateType']];
    this.parametersObj['param1'] = this.selectorData[CommonConstant.parameterMappingObj['param1']];
    this.parametersObj['param2'] = this.selectorData[CommonConstant.parameterMappingObj['param2']];
    this.parametersObj['param3'] = this.selectorData[CommonConstant.parameterMappingObj['param3']];
    this.parametersObj['param4'] = this.selectorData[CommonConstant.parameterMappingObj['param4']];
    this.parametersObj['forYear'] = this.selectorData[CommonConstant.parameterMappingObj['forYear']];
    this.parametersObj['attribute1'] = this.selectorData['attribute1'];
    this.parametersObj['policyStatus'] = this.selectorData[CommonConstant.parameterMappingObj['policyStatus']];
    this.parametersObj['applicationStatus'] = this.selectorData[CommonConstant.parameterMappingObj['applicationStatus']];
    this.parametersObj['sapOrTapToggle'] = this.sapAndTapData;
    this.parametersObj['salepolicytoggle'] = this.selectorData[CommonConstant.parameterMappingObj['salepolicytoggle']];
    this.parametersObj['agencyFilters'] = this.selectorData[CommonConstant.parameterMappingObj['agencyFilters']];
    this.parametersObj['carrierFilter'] = this.selectorData[CommonConstant.parameterMappingObj['carrierFilter']];
    if(this.parametersObj['tabId'] == 2){
      this.parametersObj['paymentStatus'] = this.selectorData['paymentStatus'];
      this.parametersObj['rtsStatus'] = this.selectorData['rtsStatus'];
      this.parametersObj['rtsCarriers'] = this.selectorData['rtsCarriers'];
    }
    return this.parametersObj;
  }

  setParameters(name, value) {
    this.parametersObj[name] = value;
  }

  resetparameters() {
    this.parametersObj['agentId'] = '';
    this.parametersObj['level'] = '';
    this.parametersObj['breadcrumbFilters'] = '';
    this.parametersObj['param5'] = 'All';
    // this.parametersObj['agentMarketingCompany'] = '';
    return this.parametersObj;
  }

  public getProductComparison(reqObj: object){
    return this.http.post<any[]>(this.baseUrl + ApiConstant[`getProductsComparision`], reqObj, this.httpOptions);
  }
  public getManagerQuarterlyIncentiveReport(reqObj: object) {
    return this.http.post<[]>(this.baseUrl + ApiConstant[`getManagerQuarterlyIncentiveReport`], reqObj, this.httpOptions).toPromise();
  }
  public getConventionIncentiveReportAris (reqObj: object) {
    return this.http.post<[]>(this.baseUrl + ApiConstant[`getConventionIncentiveReportAris`], reqObj, this.httpOptions)
  }
  public getConventionIncentiveReportAmericare  (reqObj: object) {
    return this.http.post<[]>(this.baseUrl + ApiConstant[`getConventionIncentiveReportAmericare`], reqObj, this.httpOptions)
  }
  public getProductionAllowanceIncentiveReport(reqObj: object) {
    return this.http.post<[]>(this.baseUrl + ApiConstant[`getProductionAllowanceIncentiveReport`], reqObj, this.httpOptions).toPromise();
  }
  public getASMTarget(reqObj: object) {
    return this.http.post(this.baseUrl + ApiConstant[`getASMTarget`], reqObj, this.httpOptions);
  }
  public getAgentWeeklyFastStartBonus(reqObj: object) {
    return this.http.post(this.baseUrl + ApiConstant[`getAgentWeeklyFastStartBonus`], reqObj, this.httpOptions);
  }
  public getFastStartBonusForTrainer(reqObj: object) {
    return this.http.post(this.baseUrl + ApiConstant[`getFastStartBonusForTrainer`], reqObj, this.httpOptions);
  }
  public getAgentsForTrainer(reqObj: object) {
    return this.http.post(this.baseUrl + ApiConstant[`getAgentsForTrainer`], reqObj, this.httpOptions);
  }
  public getReferralBonusIncentiveReport(reqObj: object) {
    return this.http.post(this.baseUrl + ApiConstant[`getReferralBonusIncentiveReport`], reqObj, this.httpOptions);
  }
  public getReferredAgents(reqObj: object) {
    return this.http.post(this.baseUrl + ApiConstant[`getReferredAgents`], reqObj, this.httpOptions);
  }
  public getJsonResponse(user: any) {
    return this.http.get('/dashboard_config', { "headers": new HttpHeaders().set('applicationType', 'json') })
  }

  public getFileStatus(filename) {
    return this.http.get(this.baseUrl +'getFileStatus/'+filename+'/'+new Date().toISOString(), { "headers": new HttpHeaders() })
  }

  public getMarketerCompanies(reqObj: object) {
    return this.http.get<any[]>(this.baseUrl + ApiConstant[`getMarketerCompanies`] + `?id=${reqObj[`id`]}`, this.httpOptions);
  }

  public getIncentivePrograms(reqObj: object){
    return this.http.post<any[]>(this.baseUrl + ApiConstant[`getIncentivePrograms`], reqObj, this.httpOptions);
  }

  public checkLastUpdatedDate(reqObj: object){
    return this.http.post<any[]>(this.baseUrl + ApiConstant[`checkLastUpdatedDate`], reqObj, this.httpOptions);
  }

  public checkAgentsLastUpdatedDate(reqObj: object){
    return this.http.post<any[]>(this.baseUrl + ApiConstant[`checkAgentsLastUpdatedDate`], reqObj, this.httpOptions);
  }

  public getAgentIncentivePoints(reqObj: object) {
    return this.http.post<any[]>(this.baseUrl + ApiConstant[`getAgentIncentivePoints`],  reqObj, this.httpOptions);
  }

  public getASMAgentYearEndBonusIncentiveReport(reqObj: object) {
    return this.http.post<[]>(this.baseUrl + ApiConstant[`getASMAgentYearEndBonusIncentiveReport`], reqObj, this.httpOptions).toPromise();
  }

  public getManagerWeeklyProductionIncentiveReport(reqObj: object) {
    return this.http.post(this.baseUrl + ApiConstant[`getManagerWeeklyProductionIncentiveReport`], reqObj, this.httpOptions);
  }

  public getCarriers(reqObj: object){
    let url = 'getcarriers';
    if(reqObj['clientType'] === 'carrier'){
      url = 'getAgency';
    }
    return this.http.post<any[]>(this.baseUrl + ApiConstant[url], reqObj, this.httpOptions);
  }

  public getRTScarriers(reqObj: object) {
    return this.http.post<[]>(this.baseUrl + ApiConstant[`getRTScarriers`], reqObj, this.httpOptions);
  }

  public getPolicyStatus() {
    return this.http.get<[]>(this.baseUrl + ApiConstant[`getPolicyStatus`], this.httpOptions).toPromise();
  }

  public getApplicationStatus() {
    return this.http.get<[]>(this.baseUrl + ApiConstant[`getApplicationStatus`], this.httpOptions).toPromise();
  }

  public getAgentInformation(reqObj: object) {
    return this.http.post<[]>(this.baseUrl + ApiConstant[`getAgentInformation`], reqObj, this.httpOptions).toPromise();
  }

  public getInfoAndUnderwritingInformation(reqObj: object) {
    return this.http.post<[]>(this.baseUrl + ApiConstant[`getInfoAndUnderwritingInformation`], reqObj, this.httpOptions).toPromise();
  }

  public getAgency(reqObj: object) {
    return this.http.get<[]>(`${this.baseUrl}getAgency?attribute1=${reqObj['attribute1']}&client=${reqObj['client']}&clientType=${reqObj['clientType']}&states=${reqObj['states']}`, this.httpOptions);
  }

  public getProducts(reqObj: object) {
    return this.http.get<[]>(this.baseUrl + ApiConstant[`getProducts`] + `?lob=${reqObj[`lob`]}`, this.httpOptions).toPromise();
  }

  public getLineOfBusiness(reqObj: object){
    return this.http.post<[]>(this.baseUrl + ApiConstant[`getLineOfBusiness`], reqObj, this.httpOptions);
  }

  public getFlashmessages(reqObj: object) {
    //return this.http.get(this.baseUrl + ApiConstant[`getFlashmessages`], { responseType: 'text' as 'json' });
    return this.http.post<[]>(this.baseUrl + ApiConstant[`getFlashmessages`], reqObj, this.httpOptions);
  }

  public getInfolables(reqObj: object) {
    return this.http.post<[]>(this.baseUrl + ApiConstant[`getInfolables`], reqObj, this.httpOptions);
  }

  public getInfolablesList(reqObj: object) {
    return this.http.post<[]>(this.baseUrl + 'getInfolableList', reqObj, this.httpOptions);
  }

  public getMonthlyAwardYearList(reqObj: object) {
    return this.http.post<[]>(this.baseUrl + ApiConstant[`getMonthlyAwardYearList`], reqObj, this.httpOptions);
  }

  public getMonthlyAwardsNameList(reqObj: object) {
    return this.http.post<[]>(this.baseUrl + ApiConstant[`getMonthlyAwardsNameList`], reqObj, this.httpOptions);
  }

  public updateUserActivity(reqObj: object) {
    return this.http.post<[]>(this.baseUrl + ApiConstant[`updateUserActivity`], reqObj, this.httpOptions).subscribe(res=>{return res});
  }

  public getChartData(reqObj: object) {
    // const headers = { 'content-type': 'application/json'}
    // this.http.post(this.baseURL + 'url', body,{'headers':headers});
    const body = JSON.stringify(reqObj);
    return this.http.post(this.baseUrl + reqObj[`url`], body, { responseType: 'text' as 'json' });
  }

  public getTableData(reqObj: object, api) {
    return this.http.post<[]>(this.baseUrl + api, reqObj, this.httpOptions).pipe(
      map((res: any) => {
        return res;
      }),
      catchError(_err=>{
        return of([]);
      })
    );;//.pipe(map((data: any) => data ));
    // catchError(error => { return throwError('Its a Trap!')}));
  }

  public agentPersonalDetailsApi(reqObj: object, api) {
    return this.http.post(this.baseUrl + api, reqObj, this.httpOptions)
  }

  public getAgentPersonalDetails(reqObj: object) {
    return this.http.post(this.baseUrl + reqObj[`url`], reqObj, this.httpOptions);
  }

  public productCountApiName(reqObj: object) {
    return this.http.post(this.baseUrl + reqObj[`url`], reqObj, { responseType: 'text' as 'json' });
  }

  public getPolicyAlertsInfolabels(reqObj: object) {
    return this.http.post<[]>(this.baseUrl + ApiConstant[`getPolicyAlertsInfolabels`], reqObj, this.httpOptions);
  }

  public getApplicationInfolabels(reqObj: object) {
    return this.http.post<[]>(this.baseUrl + ApiConstant[`getApplicationAlertsInfolabels`], reqObj, this.httpOptions);
  }

  public getTabData(reqObj: object) {
    return this.http.post(this.baseUrl + ApiConstant[`getTabData`], reqObj, { responseType: 'text' as 'json' });
  }

  //faqs
  public getFAQs(reqObj: object) {
    return this.http.post<[]>(this.baseUrl + ApiConstant[`getFAQs`], reqObj, this.httpOptions);
  }

  //getCountryCodes
  public getCountryCodes(reqObj: object) {
    return this.http.post<[]>(this.baseUrl + ApiConstant[`getCountryCodes`], reqObj, this.httpOptions);
  }

  //getSupportIssuesTypeList
  public getSupportIssuesTypeList(reqObj: object) {
    return this.http.post<[]>(this.baseUrl + ApiConstant[`getSupportIssuesTypeList`], reqObj, this.httpOptions);
  }

  //getWeeklyAwardYear
  public getWeeklyAwardYear() {
    return this.http.post<[]>(this.baseUrl + ApiConstant[`getWeeklyAwardYear`], {}, this.httpOptions);
  }

  //getDebtYear
  public getDebtYear() {
    return this.http.post<[]>(this.baseUrl + ApiConstant[`getDebtYear`], {}, this.httpOptions);
  }

  //createJiraTicket
  public createJiraTicket(reqObj: object, url: string) {
    const body = JSON.stringify(reqObj);
    const urlVal = url;
    return this.http.post<[]>(this.baseUrl + urlVal, body, this.httpOptions);
  }

  //supportAcknowledgementEmail
  public supportAcknowledgementEmail(reqObj: object, url: string) {
    const body = JSON.stringify(reqObj);
    const urlVal = url;
    return this.http.post<[]>(this.baseUrl + urlVal, body, this.httpOptions);
  }

  //updateFeedbackRequest
  public updateFeedbackRequest(reqObj: object, url: string) {
    // const body = JSON.stringify(reqObj);
    // const urlVal = url;
    return this.http.post<[]>(this.baseUrl + url, reqObj, this.httpOptions);

  }

  public getProductsByAgent(reqObj){
    return this.http.post<[]>(this.baseUrl + ApiConstant[`getProductsByAgent`], reqObj, this.httpOptions);
  }
  
  public getAgentRecruitmentInfolabels(reqObj){
    return this.http.post<[]>(this.baseUrl + ApiConstant[`getAgentRecruitmentInfolabels`], reqObj, this.httpOptions);
  }

  public getWeekListByYear(reqObj: object) {
    return this.http.post<[]>(this.baseUrl + ApiConstant[`getWeekListByYear`], reqObj, this.httpOptions);
  }
  
  public getBiWeeklyDateRange(reqObj: object) {
    return this.http.post<[]>(this.baseUrl + ApiConstant[`getBiWeeklyDateRange`], reqObj, this.httpOptions);
  }
  
  public getSaleTypeList(reqObj: object = {}) {
    return this.http.post<[]>(this.baseUrl + ApiConstant[`getSaleTypeList`], reqObj, this.httpOptions);
  }
  
  public saveSessionDetails(reqObj = {}) {
    return this.http.post<[]>(this.baseUrl + ApiConstant[`saveSassionData`], reqObj, this.httpOptions);
  }
  
  public getSavedSession(session_id: string) {
    return this.http.post<[]>(this.baseUrl + ApiConstant[`getSavedSession`], {session_id}, this.httpOptions);
  }
  
  public getNewsFeedUrls() {
    let origin = window.location.origin;
    let client = ''
    if(origin.includes('localhost')) client = configJson.client?.toLowerCase()+(['cdemo','ademo'].includes(configJson.client?.toLowerCase())?'':'-staging');
    else {
      client = origin.replace(/(http:\/\/)|(https:\/\/)/g, '').split('.')[0];
    }
    let url = `https://magnifactcdb.agentvizion.com/getClientNewsFeedURLs?client=${client}`
    return this.http.get<[]>(url);
  }

  public getsuportDocDetails(tabType) {
    let url = `https://magnifactcdb.agentvizion.com/getsuportDocDetails?tabType=${tabType}`
    return this.http.get<[]>(url);
  }

  public getAVURLs(){
    return this.http.post<[]>(this.baseUrl + ApiConstant[`getAVURLs`], {}, this.httpOptions);
  }
  
  public saveLastAppType(params){
    return this.http.post<[]>(this.baseUrl + ApiConstant[`saveLastAppType`], params, this.httpOptions);
  }
  
  public getLastAppType(){
    return this.http.post<[]>(this.baseUrl + ApiConstant[`getLastAppType`], {}, this.httpOptions);
  }

  public getDebtMonthlyBarChartReport(reqObj: object){
    return this.http.post<any[]>(this.baseUrl + ApiConstant[`getDebtMonthlyBarChartReport`], reqObj, this.httpOptions);
  }

  public getDebtBalanceSummaryPieReport(reqObj: object){
    return this.http.post<any[]>(this.baseUrl + ApiConstant[`getDebtBalanceSummaryPieReport`], reqObj, this.httpOptions);
  }

  public updateDeviceToken(reqObj: object){
    return this.http.post<any[]>(this.baseUrl + ApiConstant[`syncDevice`], reqObj, this.httpOptions);
  }

  public getUserOptList(){
    return this.http.post<[]>(this.baseUrl + ApiConstant[`getUserOptList`], {}, this.httpOptions);
  }

  public getScreenApplicableNotificationCategories(reqObj: object){
    return this.http.post<[]>(this.baseUrl + ApiConstant[`getScreenApplicableNotificationCategories`], reqObj, this.httpOptions);
  }

  public getUserPreferenceList(){
    return this.http.post<[]>(this.baseUrl + ApiConstant[`getUserPreferenceList`], {}, this.httpOptions);
  }


  public updateUserOptNotification(reqObj: object){
    return this.http.post<any[]>(this.baseUrl + ApiConstant[`updateUserOptNotification`], reqObj, this.httpOptions);
  }

  public updateUserPreferenceList(reqObj: object){
    return this.http.post<any[]>(this.baseUrl + ApiConstant[`updateUserPreferenceList`], reqObj, this.httpOptions);
  }

  public getTabelDataLoad(reqObj: object, api_name, sameUrl=true){
    let url = '';
    if(sameUrl) {
      url = this.baseUrl;
    } else {
      url = this.localStorageService.get("avMCCUrl")
    }
    return this.http.post<any[]>(url + api_name, reqObj, this.httpOptions);
  }

  public getExportFileStatus(filename, sameUrl=true) {
    let url = '';
    if(sameUrl) {
      url = this.baseUrl;
    } else {
      url = this.localStorageService.get("avMCCUrl")
    }
    return this.http.get(url +'getFileStatus/'+filename+'/'+new Date().toISOString(), { "headers": new HttpHeaders() })
  }

  public getNotificationsUnreadCount(){
    return this.http.post<[]>(this.baseUrl + ApiConstant[`getNotificationsUnreadCount`], {}, this.httpOptions);
  }

  public getNotificationsList(params: object){
    return this.http.post<[]>(this.baseUrl + ApiConstant[`getNotificationsList`], params, this.httpOptions);
  }

  public updateAllNotificationsReadStatus(){
    return this.http.post<[]>(this.baseUrl + ApiConstant[`updateAllNotificationsReadStatus`], {}, this.httpOptions);
  }

  public RemoveNotificationCount(){
    return this.http.post<[]>(this.baseUrl + ApiConstant[`removeNotificationCount`], {}, this.httpOptions);
  }

  public updateReadUnreadStatus(params: object){
    return this.http.post<[]>(this.baseUrl + ApiConstant[`updateReadUnreadStatus`], params, this.httpOptions);
  }

  public updateClearNotificationFlag(){
    return this.http.post<[]>(this.baseUrl + ApiConstant[`updateClearNotificationFlag`], {}, this.httpOptions);
  }

  public getAgentvizionDownloadList(reqObj: object){
    return this.http.post<any[]>(this.baseUrl + ApiConstant[`getAgentvizionDownloadList`], reqObj, this.httpOptions);
  }

  public resetSelector(){
      this.resetSapOrTapToggle();
      this.setParameters('carriers', '');
      this.setSelector('fromDate', '');
      this.setSelector('toDate', '');
      this.setSelector('forYear', '');
      this.setSelector('param2', 'All');
      this.setSelector('policyStatus', '');
      this.setSelector('marketerCompany', '');
      this.setSelector('filterDateType', '');
      this.setSelector('lob', 'lob');

      this.setParameters('salepolicytoggle', '#');
      this.setParameters('param5', 'All');
      this.setParameters('param6', 'All');
      this.setParameters('param7', 'category');
      this.setParameters('param8', '');
      this.setParameters('param9', 0);
      this.setParameters('param10', '');
      this.setParameters('param11', 'policy');
      this.setParameters('param12', 'All');
      this.setParameters('mapChartYesNo', true);
      this.setParameters('activeAgentTab', 1);
      this.setParameters('weekMonthToggle', false);
      this.setParameters('renderTableOrChart', false);
      this.setParameters('tapReportToggle', true);
      this.setParameters('contractDateInterval', '');
      this.setParameters('alertDropDownValue', ApiConstant['alertDropDownValue']);
      this.setParameters('selectedInfolabalValue', ApiConstant['selectedInfolabalValue']);
      this.setParameters('missingAgentTab', false);
      this.setParameters('agentMarketingCompany', '');
      // this.setParameters('tableDataConfig', []);
      this.setParameters('orderBy', '');
      this.setParameters('url', '');
      this.setParameters('subProduct', '');
      this.setParameters('pageNo', 0);
      this.setParameters('offset', 100);
  }

  public resetSapOrTapToggle(){
    this.sapAndTapData = this.sapOrTapToggle;
  }

  // Step 1: Define encryption and decryption functions
  public encrypt(plaintext, key) {
    var ciphertext = "";
    for (let i = 0; i < plaintext.length; i++) {
        var char = plaintext[i];
        var charCode = char.charCodeAt(0);
        var keyChar = key[i % key.length];
        var keyCharCode = keyChar.charCodeAt(0);
        var encryptedCharCode = (charCode + keyCharCode) % 256;
        ciphertext += String.fromCharCode(encryptedCharCode);
    }
    return ciphertext;
}
  /* my news/feeds apis start */
  // public getRssNews(reqObj: object){
  //   return this.http.get(this.baseUrl + ApiConstant[`getRssNews`], { responseType: 'text' as 'json' });
  // }

  // public getRssNews(reqObj: object) {
  //   return this.http.get(reqObj[`url`], { responseType: 'text' as 'json' });
  // }

  // public getFacebookData(reqObj: object) {
  //   return this.http.get(reqObj[`url`]);
  // }

  /* my news/feeds apis end */
}
