import { Injectable } from '@angular/core';
import { CommonService } from '../shared/services/common.service';
import { HttpErrorResponse } from '@angular/common/http';
import { Parser } from "json2csv";
import * as FileSaver from 'file-saver';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DataLoadStatusService {
  statusTimeout = 7000;
  my_table_modal_object;
  constructor(private _commonService: CommonService,) { }

  async exportCsv(reqBody, apiUrl, fileName, tableObject) {
    this.my_table_modal_object = tableObject;
    let msgDiv = this.createDownloadMessage();
    document.body.appendChild(msgDiv);
    let body = { ...reqBody, 'download': true }
    let res: any = await new Promise((resolve, reject) => {
      this._commonService.getTabelDataLoad(body, apiUrl, tableObject.sameUrl).subscribe((result: any) => {
        reqBody.isExport = false;
        if (result.serverFilename?.length > 0) {
          setTimeout(() => this.getFile(result.serverFilename, fileName, msgDiv), this.statusTimeout);
          return;
        }
        resolve(result)
      }, (err: HttpErrorResponse) => {
        console.log(err);
        resolve({});
      })
    })

    if(apiUrl == 'getDataLoadStatus') {
      const newArray = res.data.map(({ Description, "File Type":fileType, Channel, ...rest }) => rest);
      await this.convertJSONToCsv(newArray, null, fileName);
    } else {
      await this.convertJSONToCsv(res.data, null, fileName);
    }
    
    document.body.removeChild(msgDiv);
  }

  async convertJSONToCsv(jsonData, fields, filename, salepolicytoggle = '#') {
    let csvData;
    if (!(jsonData instanceof Array) || jsonData.length == 0) {
      console.log('Data should be an array of objects');
      csvData = 'No data available';
    } else {
      csvData = await this.toCSV(jsonData, fields, salepolicytoggle);
    }
    this.saveFile(csvData, filename)
    return filename;
  }

  async toCSV(jsonData, fields, salepolicytoggle = '#') {
    const CURRENCY_COLUMNS = ['Submitted Premium', 'Amount', 'Annualized Premium', 'Premium', 'Commission',
  'Commission Basis', 'Earned Premium', "Override", "Annual Premium", "Modal Premium", "Payment Amount", "Pending Premium"];
    const CURRENNCY_COLUMNS_PREMIUM = ['YTD', 'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec', 'Change', 'Q1', 'Q2', 'Q3', 'Q4'];
    const PERCENTAGE_COLUMNS = ['Percentage'];
    const DATE_COLUMNS = ['Date (in Local timezone)', 'Last Updated'];

    let currencyColumns = salepolicytoggle == '$' ? [...CURRENCY_COLUMNS, ...CURRENNCY_COLUMNS_PREMIUM] : [...CURRENCY_COLUMNS];
    let columnsForCurrencyFormat = jsonData[0] ? Object.keys(jsonData[0]).filter(column => currencyColumns.includes(column)) : [];
    let columnsForPercentageFormat = jsonData[0] ? Object.keys(jsonData[0]).filter(column => PERCENTAGE_COLUMNS.includes(column)) : [];
    let dateColumnsFormat = jsonData[0] ? Object.keys(jsonData[0]).filter(column => DATE_COLUMNS.includes(column)) : [];
    
    jsonData = jsonData.map(e => {
      for (let col of columnsForCurrencyFormat) {
        e[col] = this.formateCurrency(e[col]);
      }
      if (columnsForPercentageFormat.length != 0) {
        for (let col of columnsForPercentageFormat) {
          e[col] = e[col] + ' %';
        }
      }
      for(let dateCol of dateColumnsFormat){
        e[dateCol] = new Date(e[dateCol]).toLocaleString();
      }
      return e;
    })
    let json2csvParser;
    if (!(fields instanceof Array) || fields.length === 0) {
      json2csvParser = new Parser();
    } else {
      json2csvParser = new Parser({ fields });
    }
    let csvData = json2csvParser.parse(jsonData);
    return csvData;
  }

  formateCurrency(amount) {
    let formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
    });
    return formatter.format(parseFloat(amount.toString().replaceAll("$", "").replaceAll(",", "")));
  }

  saveFile(csvData, fileName) {
    let blob = new Blob(['\ufeff', csvData]);
    let reader: any = new FileReader();
    reader.onload = function () {
      let is_iPad = navigator.userAgent.match(/iPad/i) !== null;
      if (is_iPad) {
        // Chrome, Firefox, and Chrome and Safari on iOS
        var dataUrl = reader.result;
        var open = window.open(dataUrl);
        if (open === null || typeof (open) === 'undefined') {
          var blobUrl = URL.createObjectURL(blob);
          window.location.href = blobUrl;
        }
      } else {
        FileSaver.saveAs(blob, fileName);
      }
    };
    reader.readAsDataURL(blob);
  }

  createDownloadMessage() {
    let msgDiv = document.createElement("div");
    msgDiv.innerHTML = `<div class="spinner" aria-hidden="true"></div> <span class="px-1"> Preparing Download...</span>`;
    msgDiv.classList.add("download-flash-message");
    return msgDiv;
  }

  getFile(serverFilename, fileName, msgDiv, retryCount = 0) {
    let is_iPad = navigator.userAgent.match(/iPad/i) !== null;
    let retryTimeout = 5000;
    this._commonService.getExportFileStatus(serverFilename+'/'+new Date().toISOString(), this.my_table_modal_object.sameUrl)
    .subscribe(res => {
        
        if (!res || (typeof res == 'object' && Object.keys(res).length == 0)) {
            document.body.removeChild(msgDiv);
        } else if (res['isGenerated'] == true) {
            document.body.removeChild(msgDiv);
            if (is_iPad) {
                // Chrome, Firefox, and Chrome and Safari on iOS
                let dataUrl = environment.baseURL + `/download/${serverFilename}/${fileName}`;
                let open = window.open(dataUrl);
            } else {
                console.log(environment.baseURL + `/download/${serverFilename}/${fileName}`);
                FileSaver.saveAs(environment.baseURL + `/download/${serverFilename}/${fileName}`, fileName);
            }
        } else {
            setTimeout(() => this.getFile(serverFilename, fileName, msgDiv), retryTimeout);
        }
    }, (err: HttpErrorResponse) => {
        console.log(retryCount, "err", err);
        if (retryCount < 20) {
            setTimeout(() => {
                this.getFile(serverFilename, fileName, msgDiv, retryCount);
                retryCount++;
            }, retryTimeout);
        } else {
            document.body.removeChild(msgDiv);
        }
    })
  }
}
