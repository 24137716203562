<flash-messages></flash-messages>
<ng-template #myNewsContent let-c="close" let-d="dismiss">
    <div class="modal-header">
        <!-- <h4 class="modal-title" id="modal-basic-title">My Agency Feeds</h4> -->
        <span id="myNewsHostName" class="modal-title"
            style="color: #1ABB9A !important;font-size: 18px !important;font-weight: 700;">My Agency Feeds</span>
        <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
            <i class="fa fa-times" aria-hidden="true"></i>
        </button>
    </div>
    <div class="modal-body news-container-modal-body">
        <div *ngFor="let news of myFeedsList" (click)="onSelectionNews(news)">
            <div class="news-container d-flex align-items-center">
                <div class="p-2 text-center" style="width: 15%;">
                    <img *ngIf="news.source?.source?.toLowerCase().includes('facebook')" src="assets/images/resources_facebook_round.png">
                    <img *ngIf="news.source?.source?.toLowerCase().includes('youtube')" src="assets/images/resources_youtube_round.png">
                    <img *ngIf="news.source?.source?.toLowerCase().includes('twitter')" src="assets/images/resources_twitter_round.png">
                    <img *ngIf="news.source?.source?.toLowerCase().includes('linkedin')" src="assets/images/resources_linkdin_round.png">
                    <img *ngIf="news.source?.source?.toLowerCase().includes('instagram')" src="assets/images/resources_instagram_round.png"
                        width="48">
                    <img *ngIf="news.source?.source?.toLowerCase().includes('rssfeed')" src="assets/images/resources_rssfeed_round.png">
                </div>
                <div class="news-right-container p-2 d-flex flex-column" style="width: 85%;">
                    <a class="news-title overflow-ellipsis cursor-pointer"> {{ getDescription(news.title) }} </a>
                    <span id="newsDescription" class="mt-2 text-justify"> {{ getDescription(news.description) }}</span><br>
                    <div class="news-creator-and-date w-100">
                        <span class="font-italic">by {{ news.dc_creator }}</span>
                        <span class="pull-right"> {{ news.pubDate | date: 'dd-MMM-yyyy' }} </span>
                    </div>
                </div>
            </div>
            <hr class="news-horizontal-line">
        </div>
    </div>
</ng-template>

<ng-template #cancelSubscription let-c="close" let-d="dismiss">
    <div class="modal-header">
        <!-- <h4 class="modal-title" id="modal-basic-title">My Agency Feeds</h4> -->
        <span id="host_name" class="modal-title w-100"
            style="color: #1ABB9A !important;font-size: 18px !important;font-weight: 700;">     
        <span>Do you want to cancel the AgentVizion registration?</span></span>
        <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
            <i class="fa fa-times" aria-hidden="true"></i>
        </button>
    </div>
    <div class="modal-body stripe-container-modal-body">
        <div class="cancel-subscription-feedback-msg" >
            <span><b>Sorry to see you go...</b></span>
            <span><b>Please tell us the reason for your cancellation.</b></span>
            <textarea id="feedbackReason" rows="4" cols="50"></textarea>
        </div>
        <div class='cancel-msg'>
            <span class="cancel-subscription-msg">
            Cancellation will take effect at the end of your billing cycle. Upon cancellation, no refund will be issued, but you will not be charged the next time.
            </span>
        </div>
        
        <div class="panel-body modal-body table-responsive text-align-center" id="cancelSubscriptionPopupContainer">
            <button id="blockBtnId" type="button" class="btn btn-primary margin-right-10 green-bgColor" (click)="cancelSubscriptionOk()">Yes</button>
            <button type="button" class="btn btn-secondary green-bgColor" style="color: #fff;" (click)="d('Cross click')">No</button>
        </div>
    </div>
</ng-template>

<!-- <ng-container *ngTemplateOutlet="subscriptionDetail; context:subscriptionDetail"></ng-container> -->
<ng-template #subscriptionData let-c="close" let-d="dismiss">
    <div class="modal-header">
        <!-- <h4 class="modal-title" id="modal-basic-title">My Agency Feeds</h4> -->
        <span id="myNewsHostName" class="modal-title"
            style="color: #1ABB9A !important;font-size: 18px !important;font-weight: 700;">     
        <span>My Registration Details</span></span>
        <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
            <i class="fa fa-times" aria-hidden="true"></i>
        </button>
    </div>
    <div class="modal-body stripe-container-modal-body">
        <div class="col-12 d-flex justify-content-center subscription-detail">
            <div class='w-100' style="max-width: 500px;">
                <span class="width-140">Registration type </span><span>&nbsp; : &nbsp;{{subscriptionDetail.subscriptionType}}</span><br>
                <!-- <span class="width-140">Plan </span><span>&nbsp : &nbsp{{subscriptionDetail.plan}}</span><br> -->
                <span class="width-140">Registration on </span><span>&nbsp; : &nbsp;{{subscriptionDetail.subscriptionDate  | date}}</span><br>
                <span class="width-140">Current cycle </span><span>&nbsp; : &nbsp;{{subscriptionDetail.currentCycle[0]|date}} to {{subscriptionDetail.currentCycle[1]|date}}</span><br>
                <span class="width-140">Next invoice </span><span>&nbsp; : &nbsp;{{subscriptionDetail.nextInvoice | date}}</span><br>
            </div>
            </div>
    </div>
</ng-template>

<ng-template #notificationContent let-c="close" let-d="dismiss">
    <div class="modal-header">
        <span id="myNewsHostName" class="modal-title"
            style="color: #1ABB9A !important;font-size: 18px !important;font-weight: 700;">Notifications</span>
        <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
            <i class="fa fa-times" aria-hidden="true"></i>
        </button>
    </div>
    <div class="modal-body news-container-modal-body" style="font-size: 1rem;min-height: 230px;max-height: 525px;" [ngStyle]="getNotificationPopupStyles()" (mouseover)="onMouseover($event)">

        <div class="tableContainer py-1">
            <table class="w-100">
                <thead>
                    <tr  class="notification-management-support-header-container">
                        <th scope="col" class="tableColumnWidth text-start py-2"><i class="fa fa-list-alt"></i> Category</th>
                        <th scope="col" class="tableColumnWidth text-center py-2" *ngIf="showMedium('Email') && !checkClientDisablity(0)">
                            <i class="fa fa-envelope"></i> {{getLabels(mediumArray[0]) | snakeCaseToTitleCase }}</th>
                        <th scope="col" class="tableColumnWidth text-center py-2" *ngIf="showMedium('Web') && !checkClientDisablity(1)">
                            <i class="fa fa-globe"></i> {{getLabels(mediumArray[1]) | snakeCaseToTitleCase }}</th>
                        <th scope="col" class="tableColumnWidth text-center py-2" *ngIf="showMedium('Mob') && !checkClientDisablity(2)">
                            <i class="fa fa-mobile"></i> {{getLabels(mediumArray[2]) | snakeCaseToTitleCase }}</th>
                        <th scope="col" class="tableColumnWidth text-center py-2" *ngIf="showMedium('SMS') && !checkClientDisablity(3)">
                            <i class="fa fa-comment"></i> {{getLabels(mediumArray[3]) | snakeCaseToTitleCase }}</th>
                    </tr>
                    <tr *ngIf="userNotificationControls.length" class="tableBorderBottom">
                        <th scope="col" class="text-start py-2"></th>
                        <th scope="col" class="text-center py-2" *ngIf="showMedium('Email') && !checkClientDisablity(0)"
                            [attr.data-toggle]="checkClientDisablity(0) ? 'tooltip' : null"
                            data-bs-placement="bottom"
                            [attr.title]="checkClientDisablity(0) ? disabledClientTooltipText : null">
                            <div class="form-check form-switch d-flex justify-content-center">
                                <input class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckChecked" [checked]="userNotificationControls[0].value" [disabled]="checkClientDisablity(0)" (change)="updateUserOptNotification($event, 'Email', 0)">
                            </div>
                        </th>
                        <th scope="col" class="text-center py-2" *ngIf="showMedium('Web') && !checkClientDisablity(1)"
                            [attr.data-toggle]="checkClientDisablity(1) ? 'tooltip' : null"
                            data-bs-placement="bottom"
                            [attr.title]="checkClientDisablity(1) ? disabledClientTooltipText : null">
                            <div class="form-check form-switch d-flex justify-content-center">
                                <input class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckChecked" [checked]="userNotificationControls[1].value" [disabled]="checkClientDisablity(1)" (change)="updateUserOptNotification($event, 'Web', 1)">
                            </div>
                        </th>
                        <th scope="col" class="text-center py-2" *ngIf="showMedium('Mob') && !checkClientDisablity(2)"
                            [attr.data-toggle]="checkClientDisablity(2) ? 'tooltip' : null"
                            data-bs-placement="bottom"
                            [attr.title]="checkClientDisablity(2) ? disabledClientTooltipText : null">
                            <div class="form-check form-switch d-flex justify-content-center">
                                <input class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckChecked" [checked]="userNotificationControls[2].value" [disabled]="checkClientDisablity(2)" (change)="updateUserOptNotification($event, 'Mob', 2)">
                            </div>
                        </th>
                        <th scope="col" class="text-center py-2" *ngIf="showMedium('SMS') && !checkClientDisablity(3)"
                            [attr.data-toggle]="checkClientDisablity(3) ? 'tooltip' : null"
                            data-bs-placement="bottom"
                            [attr.title]="checkClientDisablity(3) ? disabledClientTooltipText : null">
                            <div class="form-check form-switch d-flex justify-content-center">
                                <input class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckChecked" [checked]="userNotificationControls[3].value" [disabled]="checkClientDisablity(3)" (change)="updateUserOptNotification($event, 'SMS', 3)">
                            </div>
                        </th>
                    </tr>
                    </thead>
                    <tbody *ngIf="userNotificationControls.length">
                        <ng-container *ngFor="let category of screenNotificationCategories; let i = index">
                            <tr *ngIf="checkIfAnyOneSelectedAtClientLevel(category)" class="tableBorderBottom">
                                <th scope="row" class="text-start py-2 categoryFont">{{category.display_name}}
                                    <span type="button" data-toggle="tooltip" data-bs-placement="bottom"
                                        title="{{category?.description}}">
                                        <svg _ngcontent-leq-c163="" xmlns="http://www.w3.org/2000/svg" width="16"
                                            height="16" fill="#ffffff" viewBox="0 0 16 16"
                                            class="bi bi-info-circle-fill" style="-webkit-filter: drop-shadow( 0px 0px 2px rgba(0, 0, 0, .7));filter: drop-shadow( 0px 0px 2px rgba(0, 0, 0, .7));">
                                            <path
                                                d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm.93-9.412-1 4.705c-.07.34.029.533.304.533.194 0 .487-.07.686-.246l-.088.416c-.287.346-.92.598-1.465.598-.703 0-1.002-.422-.808-1.319l.738-3.468c.064-.293.006-.399-.287-.47l-.451-.081.082-.381 2.29-.287zM8 5.5a1 1 0 1 1 0-2 1 1 0 0 1 0 2z">
                                            </path>
                                        </svg>

                                    </span>
                                </th>
                                <td class="text-center py-2" *ngIf="showMedium('Email') && !checkClientDisablity(0)"
                                    [attr.data-toggle]="checkCategoryApplicable(category, 'Email') || checkClientDisablity(0) ? 'tooltip' : null"
                                    data-bs-placement="bottom" [attr.title]="checkCategoryApplicable(category, 'Email') ? categoryTooltipText : checkClientDisablity(0) ? disabledClientTooltipText : null">
                                    <div>
                                        <input type="checkbox" class="form-check-input cumulativeWeeks-input ms-2"
                                        [ngClass]="checkCategoryApplicable(category, 'Email') ? '' : checkClientDisablity(0) ? 'MagnifactDisabled' : ''"
                                        [disabled]="checkCategoryApplicable(category, 'Email') || checkUserDisablity(0) || checkClientDisablity(0)"
                                        name="{{category.category_name}}" [checked]="getUserCheckValue('Email', category)"
                                        (change)="updateUserPreferenceList($event, category, 'Email', 0)">
                                    </div>
                                </td>
                                <td class="text-center py-2" *ngIf="showMedium('Web') && !checkClientDisablity(1)"
                                [attr.data-toggle]="checkCategoryApplicable(category, 'Web') || checkClientDisablity(1) ? 'tooltip' : null"
                                data-bs-placement="bottom" [attr.title]="checkCategoryApplicable(category, 'Web') ? categoryTooltipText : checkClientDisablity(1) ? disabledClientTooltipText : null">
                                    <div>
                                        <input type="checkbox" class="form-check-input cumulativeWeeks-input ms-2"
                                        [ngClass]="checkCategoryApplicable(category, 'Web') ? '' : checkClientDisablity(1) ? 'MagnifactDisabled' : ''"
                                        [disabled]="checkCategoryApplicable(category, 'Web') || checkUserDisablity(1) || checkClientDisablity(1)"
                                        name="{{category.category_name}}" [checked]="getUserCheckValue('Web', category)"
                                        (change)="updateUserPreferenceList($event, category, 'Web', 1)">
                                    </div>
                                </td>
                                <td class="text-center py-2" *ngIf="showMedium('Mob') && !checkClientDisablity(2)"
                                [attr.data-toggle]="checkCategoryApplicable(category, 'Mob') ||  checkClientDisablity(2) ? 'tooltip' : null"
                                data-bs-placement="bottom" [attr.title]="checkCategoryApplicable(category, 'Mob') ? categoryTooltipText : checkClientDisablity(2) ? disabledClientTooltipText : null">
                                    <div>
                                        <input type="checkbox" class="form-check-input cumulativeWeeks-input ms-2"
                                        [ngClass]="checkCategoryApplicable(category, 'Mob') ? '' : checkClientDisablity(2) ? 'MagnifactDisabled' : ''"
                                        [disabled]="checkCategoryApplicable(category, 'Mob') || checkUserDisablity(2) || checkClientDisablity(2)"
                                        name="{{category.category_name}}" [checked]="getUserCheckValue('Mob', category)"
                                        (change)="updateUserPreferenceList($event, category, 'Mob', 2)">
                                    </div>
                                </td>
                                <td class="text-center py-2" *ngIf="showMedium('SMS') && !checkClientDisablity(3)"
                                [attr.data-toggle]="checkCategoryApplicable(category, 'SMS') ||  checkClientDisablity(3) ? 'tooltip' : null"
                                data-bs-placement="bottom" [attr.title]="checkCategoryApplicable(category, 'SMS') ? categoryTooltipText : checkClientDisablity(3) ? disabledClientTooltipText : null">
                                    <div>
                                        <input type="checkbox" class="form-check-input cumulativeWeeks-input ms-2"
                                        [ngClass]="checkCategoryApplicable(category, 'SMS') ? '' : checkClientDisablity(3) ? 'MagnifactDisabled' : ''"
                                        [disabled]="checkCategoryApplicable(category, 'SMS') || checkUserDisablity(3) || checkClientDisablity(3)"
                                        name="{{category.category_name}}" [checked]="getUserCheckValue('SMS', category)"
                                        (change)="updateUserPreferenceList($event, category, 'SMS', 3)">
                                    </div>
                                </td>
                            </tr>
                        </ng-container>
                        <div *ngIf="!checkIfAnyCategoryAvailableClientLevel()" class="text-center">
                            No Category is enabled.
                        </div>
                    </tbody>
                </table>
            </div>

        <div class="text-center text-success" *ngIf="successMsgFlag"><strong>{{successMsg}}</strong></div>
    </div>
</ng-template>

<ng-template #showNotificationContent let-c="close" let-d="dismiss">
    <div class="modal-header d-flex">
        <span id="myNewsHostName" class="modal-title"
            style="color: #1ABB9A !important;font-size: 18px !important;font-weight: 700;"> Notifications</span>
        <div class="d-flex align-items-right">
            <button type="button" class="mx-1 close" (click)="MarkAllAsRead()">
                Mark all as read
            </button>
            <button type="button" class="mx-1 close" (click)="clearAllNotifications()">
                Clear All
            </button>
            <button type="button" class="mx-1 close" aria-label="Close" (click)="d('Cross click')">
                <i class="fa fa-times" aria-hidden="true"></i>
            </button>
        </div>
    </div>
    <div class="modal-body news-container-modal-body p-0" style="min-height: 150px;max-height: 525px;" [ngStyle]="getShowNotificationPopupStyles()">
        <div *ngIf="notificationContentData?.length" class="px-3">
            <div *ngFor="let item of notificationContentData;" [ngClass]="item.is_read == 0 ? 'background-secondary5' : ''">
                <div class="news-container d-flex align-items-center py-2 ps-3" (click)="redirectToNotifictionURL($event, item)" style="border-bottom: 1px solid #b7b3b3;">
                    <div class="status-indicator-green" *ngIf="item.is_read == 0" style="max-width: 10px;max-height: 10px;cursor: pointer;"  (click)="MarkAsReadUnRead($event, item.msg_id, 1)" [attr.data-toggle]="'tooltip'"
                    data-bs-placement="right" [attr.title]="'Mark as Read'"></div>

                    <div class="status-indicator-invalid" *ngIf="item.is_read == 1" style="max-width: 10px;max-height: 10px;cursor: pointer;" (click)="MarkAsReadUnRead($event, item.msg_id, 0)" [attr.data-toggle]="'tooltip'"
                    data-bs-placement="right" [attr.title]="'Mark as Unread'"></div>
                    <div class="news-right-container ms-3 d-flex flex-column w-100 my-2" style="cursor: pointer;">
                        <div class="news-title overflow-ellipsis cursor-pointer fw-bold mb-2" style="font-size: .9rem;"> {{item.title}}</div>
                        <div class="news-creator-and-date w-100 mt-1" style="font-size: .8rem;">
                            <span class="text-justify"> {{item.notification}} </span>
                            <span class="pull-right mx-3 fw-bold"> {{ item.etl_run_date | date: 'dd-MMM-yyyy' }} </span>
                        </div>
                    </div>
                    
                </div>
                <!-- <hr class="news-horizontal-line m-2"> -->
            </div>
        </div>
        <div *ngIf="!notificationContentData?.length" class="text-center">No Data Available</div>
    </div>
    <div class="modal-footer d-block p-0">
        <app-av-paginator [paginationConfig]="paginationConfig"
			(pageUpdateEvent)="paginatorClickEvent($event)"></app-av-paginator>
    </div>
</ng-template>

<ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="ball-scale-multiple">
    <p style="font-size: 20px; color: white">Loading...</p>
</ngx-spinner>

<div *ngIf="isHeaderLoaded" class="header-main-container">
    <div class="d-flex header m-0 header-style">
        <div class="logodiv p-2 p-0 d-flex"><img class="logo m-auto background-white" src="assets/{{logo}}"></div>
        <div class="px-0 header-center-container">
            <nav class="navbar navbar-expand-lg navbar-light h-100 pb-0" id="pageTabs">
                <!-- <a class="navbar-brand" href="#">Navbar</a> -->

                <button class="navbar-toggler background-white" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav"
                    aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                    <span class="navbar-toggler-icon"></span>
                </button>

                <div class="collapse navbar-collapse w-100" id="navbarNav">
                    <ul class="navbar-nav w-100 h-100">
                        <li *ngFor="let tab of tabsData" class="nav-item px-1 cursor-pointer"
                            id={{tab.tab_id}} [ngClass]="tab.tab_id == selectedTab ? 'active': '' ">
                            <!-- <span *ngIf=" tab.id !== 'tab5' && tab.id !== 'tab6'" [ngClass]="{'disabled' : tab.defaultTab}"  class="fa fa-play-circle helpLink activeLink" (click)="openHelpLink({data:tab})"></span> [ngClass]="(tab.id === 'tab5' || tab.id ==='tab6') ? 'margin-top-20':''" -->
                            <a id="{{tab.tab_id}}" class="nav-link pb-3 h-100"
                                (click)="getTabContent({id:tab.tab_id, tabObj: tab})">{{tab.tab_name}}</a>
                        </li>
                    </ul>
                </div>
            </nav>
        </div>
        <div *ngIf="socialMediaFeedUrls.length > 0" class="d-flex align-items-center justify-content-center">
            <div class="p-1 cursor-pointer" style="border-radius: 50%;">
                <img width="25" src="assets/images/Icon_News_white.svg" alt="" (click)="onMyNews(myNewsContent)">
            </div>
        </div>
        <div *ngIf="avLiteUrl" class="d-flex align-items-center justify-content-center lite-advanced-switch">
            <ui-switch checkedLabel="LITE" (valueChange)="goToAVLite($event)"
            uncheckedLabel="PRO" 
            size="small"></ui-switch>
        </div>
        <div class="dropdown avatar header-right-container d-flex justify-content-end align-items-center px-0">
            <div class="d-flex align-items-center text-white font-weight-bolder header-right-avatar px-3">
                <div class="d-flex flex-column">
                    <div class="avatar_icon">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                            class="bi bi-person " viewBox="0 0 16 16">
                            <path
                                d="M8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0zm4 8c0 1-1 1-1 1H3s-1 0-1-1 1-4 6-4 6 3 6 4zm-1-.004c-.001-.246-.154-.986-.832-1.664C11.516 10.68 10.289 10 8 10c-2.29 0-3.516.68-4.168 1.332-.678.678-.83 1.418-.832 1.664h10z" />
                        </svg>
                    </div>
                </div>

                <div class="d-flex flex-column px-2">
                    <button class="btn dropdown-toggle avatarbtn px-2 p-0" type="button" id="dropdownMenuButton"
                        data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        {{userInfo?userInfo['name']:''}}
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                            class="bi bi-chevron-down" viewBox="0 0 16 16">
                            <path fill-rule="evenodd"
                                d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z" />
                        </svg>
                    </button>
                    <div class="labeladmi px-2">{{loginUserRole}}</div>
                    <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                            class="bi bi-caret-up-fill arrow " viewBox="0 0 16 16">
                            <path
                                d="m7.247 4.86-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z" />
                        </svg>
                        <img class="logo" src="assets/images/user-icon.svg">
                        <!-- <img class="logo" src="assets/{{logo}}"> -->
                        <div class="name text-color2 mt-2">{{userInfo?userInfo['name']:''}}</div>
                        <div class="name_d mb-2"><small><small>{{loginUserRole}}</small></small></div>
                        <div class="line"></div>
                        <!-- <ul>
                <li>Registration</li>
                <li>Payment Info.</li>
            </ul> -->
                        <div class="line"></div>
                        <ul class="text-start mt-2">
                            <ng-container *ngFor="let item of showMoreOptions">
                                <li class="cursor-pointer" *ngIf="item.visible && checkOptionAllowedByRole(item)">
                                    <a id="notificationDetails" (click)="this[item.functionToCall]()">{{item.displayName}}</a>
                                </li>
                            </ng-container>
                            <li class="cursor-pointer" *ngIf="showNotificationControl">
                                <a id="notificationDetails" (click)="setNotifications(notificationContent)">Notifications</a>
                            </li>
                            <!-- <li class="cursor-pointer" *ngIf="socialMediaFeedUrls.length > 0">
                                <a id="myFeedsDetails" (click)="onMyNews(myNewsContent)">My Feeds</a>
                            </li> -->
                            <li class="cursor-pointer">
                                <a (click)="onChangePassword()">Change Password</a>
                            </li>
                           <li *ngIf="userRole!='Manager'" class="cursor-pointer">
                            <a (click)="cancelSubscriptions(cancelSubscription)" id="cancelSubscription">Cancel my registration</a>
                           </li>
                           <li *ngIf="userRole!='Manager'" class="cursor-pointer">
                            <a (click)="updateCard()" id="updateCard">Update my card</a>                             
                           </li>
                           <li *ngIf="userRole!='Manager'" class="cursor-pointer">
                            <a (click)="subscriptionDetails(subscriptionData)" id="subscriptionDetails">My registration details</a>                           
                           </li>
                           <!-- <li *ngIf="avLiteUrl" class="cursor-pointer">
                            <a (click)="goToAVLite()" id="avLiteBtn">Go to AV Lite</a>                           
                           </li> -->
                        </ul>
                        <button type="button" class="btn btn-primary mt-4 background-primary3 py-1" (click)="logout()">SIGN OUT</button>
                        
                    </div>
                </div>
                <div class="d-flex flex-column px-2" *ngIf="showNotificationBell">
                    <button type="button" class="btn btn-success shadow-none position-relative notification-bell" (click)="showNotification(showNotificationContent)">
                        <i class="fa fa-regular fa-bell" aria-hidden="true"></i>
                      <span class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-light text-primary" *ngIf="NotificationsUnreadCount > 0">
                        {{NotificationsUnreadCount}}
                      </span>
                    </button>
                </div>
            </div>
        </div>
    </div>
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 header-container d-none">
        <div class="col-xs-2 col-sm-2 col-md-2 col-lg-2 margin-top-10 margin-bottom-10">
            <!-- <img id="logoImage" class="user-avatar logo"> -->
            <img src="assets/{{logo}}" class="header-logo-img">
        </div>
        <div class="col-xs-8 col-sm-8 col-md-8 col-lg-8 tabs-container">
            <ul class="nav nav-tabs top-tab" id="pageTabs">
                <!-- [ngClass]="(tab.defaultTab)?'active':''" -->
                <li *ngFor="let tab of tabsData" id={{tab.id}}>
                    <!-- <span *ngIf=" tab.id !== 'tab5' && tab.id !== 'tab6'" [ngClass]="{'disabled' : tab.defaultTab}"  class="fa fa-play-circle helpLink activeLink" (click)="openHelpLink({data:tab})"></span> [ngClass]="(tab.id === 'tab5' || tab.id ==='tab6') ? 'margin-top-20':''" -->
                    <a id="{{tab.id}}" [ngClass]="tab.id === selectedTab ? 'active' : 'none'" class="nav-link"
                        (click)="getTabContent({id:tab.id})">{{tab.label}}</a>
                </li>
                <!-- *ngIf="tab.defaultTab" [ngClass]="{'disabled' : tab.defaultTab}"  -->
            </ul>
        </div>
        <div class="col-xs-2 col-sm-2 col-md-2 col-lg-2 padding-top-25">
            <div>
                <div class="vertical-grey-line"></div>
                <div class="profile-container">
                    <div>
                        <i class="fa fa-user fa-lg user-logo"></i>
                    </div>
                    <div class="user-container">
                        <span id="loggedUserName" class="user-name">{{userInfo?userInfo['name']:''}}</span><br>
                        <span id="loggedUserRole" class="user-name-role ">{{userRole}}</span>
                    </div>
                </div>
                <div>
                    <div class="dropdown-toggle profile-open" data-bs-toggle="dropdown">
                        <ul class="dropdown-menu margin-top-20">
                            <div class="user-profile">
                                <i class="fa fa-user-circle-o fa-3x" aria-hidden="true"></i><br>
                                <span id="UserName">{{userInfo?userInfo['name']:''}}</span><br>
                                <span id="UserRole">{{userRole}}</span>
                            </div>
                            <div class="margin-top-15 text-align-center">
                                <span class="hr-line"></span>
                            </div>
                            <div>
                                <!-- <li class="text-align-center">
                                    <a id="subscriptionDetails" (click)="onMyNews(myNewsContent)">My Feeds</a>
                                </li> -->
                            </div>
                            <!-- <li class="text-align-center">
                                <a id="subscriptionDetails" (click)="onChangePassword()">Change Password</a>
                            </li>
                            <div class="text-align-center cursor-pointer;">
                                <a (click)="logout()" ><i class="icon-off" ></i> Logout</a>
                            </div> -->
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<button hidden="true" data-bs-toggle="modal" id="openCardModal" data-bs-target="#cardDetailsModal">HiddenModal</button>

<button hidden="true" data-bs-toggle="modal" id="registrationModal" data-bs-target="#regDetailsModal">HiddenModal</button>

<app-cards *ngIf="showCardsPopup" [registrationDetails]="registrationDetails"></app-cards>