import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { FlashMessagesService } from 'angular2-flash-messages';
import { AuthService} from '../auth.service';
import $ from 'jquery';
import {NgbModal, NgbModalOptions} from '@ng-bootstrap/ng-bootstrap';
import { ModalComponent } from '../../shared/modal/modal.component';
import { CommonService } from '../../shared/services/common.service';
import { LocalstorageService } from 'src/app/shared/services/localstorage.service';
import { NgxSpinnerService } from "ngx-spinner";
import {configJson, DashboardConfigService} from '../../shared/services/dashboard-config.service';
import { HttpErrorResponse } from '@angular/common/http';
import { v4 as uuidv4 } from 'uuid';
// import { Modal } from 'bootstrap';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})

export class LoginComponent implements OnInit, AfterViewInit {
    @ViewChild('appModalPopup') appModalPopup: ModalComponent;
    returnUrl: string;
    title = 'AgentVizion';
    toggleUser: string = '';
    toggleUserFlag: Boolean = false;
    npnFlag: Boolean = false;
    verifyUser: Boolean = false;
    isAgree: Boolean = true;
    acceptEulaFlag: Boolean = true;
    isEulaAccepted: number ;
    user: any = {
        username: '',
        npn: '',
        password: '',
        otp: '',
        refreshToken : null,
        rememberMe: false,
        npnFlag: false
    };
    otpScreenUserData:any = {
        username: '',
        npn: '',
        password: '',
        otp: '',
        refreshToken : null,
        rememberMe: false,
        npnFlag: false
    };
    routeParams: object = {
        url: '',
        queryParam: ''
    }
    forgotVisible: boolean = true;
    logo: string;
    app_version: number;
    eulaDisplay: boolean = false;

    lockedMessage: string = 'User is locked. Please contact your Helpdesk. Your account will be unlocked after 15 mins.';
    npnLockedMessage: string = 'Your Account is now locked. Please contact your Helpdesk or wait 15 minutes to try again.';
    invalidLoginAttemptMsg: string =  'You have exceeded the limit of invalid login attempts(3).';
    accountVerificationMsg: string = 'Please verify your account with your registered email address prior to logging in with your NPN.';
    accountVerifiedMsg: string = 'Thank you for verifying your account. You may now access AgentVizion2GO.';
    enterOTPMsg: string = 'Please enter OTP sent to your email id for login.';
    enterNPNMsg: string = 'Please enter valid NPN';
    forgotPasswordMsg: string = 'Please check your mailbox for forgot password link to change the password';
    userId: number ;

    modalOptions:NgbModalOptions;
    currentUrl: string;
    flashMessageDefaultTime = 4000;
    SSOLogin: any;
    lockUsername: boolean = false;
    isAgentVerified: boolean = true;
    loginScreen: Boolean = true;
    resentOtpFlag: Boolean = false;
    reDirecting: boolean = true;
    session_id;
    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private flashMessagesService: FlashMessagesService,
        private authService: AuthService,
        private commonService: CommonService,
        private modalService: NgbModal,
        private localStorageService: LocalstorageService,
        private spinner: NgxSpinnerService,
        private dashboardConfigService: DashboardConfigService,
    ) {
        this.modalOptions = {
            backdrop:'static',
            backdropClass:'customBackdrop'
        }
        router.events.subscribe((event) => {
            if (event instanceof NavigationEnd ) {
            this.currentUrl = event.url;
            // get return url from route parameters or default to '/'
            this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '';
            }
        });
        this.modalService.dismissAll();
        // const modalElements = document.querySelectorAll('.modal');
        // modalElements.forEach(modalElement => {
        // const modal = Modal.getInstance(modalElement);
        // if (modal) {
        //     modal.hide();
        // }
        // });
    }

    async ngOnInit(){
        this.session_id = this.route.snapshot.queryParamMap.get('session_id');
        this.getSavedSession(this.session_id);
        this.localStorageService.remove('debtYear');
        if(!this.localStorageService.get('browserInstance')) {
            const instanceId = this.generateInstanceId();
            this.localStorageService.set('browserInstance', instanceId);
        }
        !configJson ? await this.dashboardConfigService.getDashboardConfig():'';
        this.SSOLogin = configJson?.ssoLogin;
        if(this.SSOLogin && this.SSOLogin.includes('no')){
            this.spinner.show();
            $('#username').focus();
            setTimeout(()=> {
                $('#correctOtpContainer, #optinalNPNContainer').hide();
                // $('#username, #npn, #pass, input').val('');

                history.pushState(null, null, location.href);
                window.onpopstate = function() {
                    history.go(1);
                };
            },0)
            // this.getClientConfig();
        }
        this.getClientConfig();
        if(this.localStorageService.get('_rt')) {
            await this.checkRefreshToken();
        }
    }

    ngAfterViewInit() {
        const backdrop = document.querySelector('.modal-backdrop');
        if (backdrop) {
            backdrop.parentNode.removeChild(backdrop);
        }
    }

    // Function to generate a random number
    generateInstanceId() {
        return 'BR-' + uuidv4(); // Change the range or algorithm as needed
    }

    openEulaContent(content){
        this.modalService.open(content, {size: 'xl'});
    }

    getClientConfig(){
        this.authService.getClientConfig().subscribe(response => {
        this.commonService.hideLoader()
        this.logo = response[0].logo;
        this.app_version = response[0].app_version;
        localStorage.setItem('app_version', this.app_version.toString());
        this.toggleUser = response[0].toggle_user;
        this.commonService.setLogoPath(this.logo);
        let notificationArray = response[0].notification_control.split(',');
            // Convert array to key-value pairs
            let notification_control = notificationArray.map(item => {
            const [key, value] = item.split('=');
            return { key, value: +value };
            });
            this.commonService.setNotificationControl(notification_control);
        if (this.toggleUser === 'yes') {
            this.toggleUserFlag = true;
            $('#toggleUserContainer').show();
            $('#userNameContainer, #npnContainer, #forgetNpnContainer').hide();
            $('#emailUserNameContainer').show();
        } else {
            this.toggleUserFlag = false;
            $('#toggleUserContainer, #forgetNpnContainer, #otpContainer').hide();
            $('#userNameContainer').show();
        }
        });
    }

    flashMessagesFunction(msg: any, alert: any, timeout:any){
        //this.flashMessagesService.show(msg, { cssClass: 'alert-danger', timeout: 1000000, closeOnClick:true, showCloseBtn: true });
        if(msg !== ""){
            if(alert === 'danger'){
                this.flashMessagesService.show(msg, { cssClass: 'alert-danger', timeout: timeout, closeOnClick:true, showCloseBtn: true });
            } else {
                this.flashMessagesService.show(msg, { cssClass: 'alert-success', timeout: timeout, closeOnClick:true, showCloseBtn: true});
            }
        }
    }

    acceptEula = function(user, usuario){
        if(this.isEulaAccepted == 1) {
            var paramObj = {'param1': user.username, 'param2': user.npnFlag, 'param3': usuario[0].id, 'param4': '' }
            this.authService.acceptEula(paramObj, function() {});
        }
    }

    otpLoginCheck(user:any) {
        this.spinner.show();
        this.commonService.showLoader();
        if (user.otp) {
            var toggleUsertab = $('ul#toggleUser').find('li a.active').text().trim();
            if (toggleUsertab === 'NPN USER') {
                user = {
                    npnFlag: true,
                    username: user.npn,
                    password: this.user['password'],
                    otp: user.otp
                };
            } else {
                user = {
                    npnFlag: false,
                    username: this.user['username'],
                    password: this.user['password'],
                    otp: user.otp
                };
            }
            this.setOtpForLoginOtp(user);
        } else {
            this.flashMessagesFunction('Please insert a valid OTP', 'danger', this.flashMessageDefaultTime);
        }
    }

    setOtpForLoginOtp(user: any) {
        this.authService.setUserOtp({ 'param1': user.username, 'param2': user.otp, 'param3': user.npnFlag }).subscribe(response => {
            this.otpLogin(user);
        });
    }

    navigateToHomePage(user, redirect=false){
        this.spinner.show();
        this.checkStripeSubscription().then(res=>{
            this.commonService.getTabData({}).subscribe(res => {
                this.commonService.tabData = res;
                if(!this.commonService.tabData.length) {
                    this.spinner.hide();
                    this.flashMessagesFunction("Something went wrong, Please try again", 'danger', 2500 );
                    this.router.navigate(['/login'], { queryParams: { } });
                    return;
                }
                this.spinner.show();
                let tabName = this.commonService.tabData[0].tab_name.toLowerCase();
                let subtab='';
                if(this.commonService.tabData[0].subTabs instanceof Array && this.commonService.tabData[0].subTabs[0]) {
                    subtab = this.commonService.tabData[0].subTabs[0].sub_tab_name.toLowerCase();
                }
                if(redirect){
                    this.router.navigate(['app/'+tabName], { queryParams: {session_id:this.session_id} });
                    return;
                }
                if(this.acceptEulaFlag) {
                    this.authService.acceptEula({ 'param1': user.username, 'param2': user.npnFlag }).subscribe(res =>{

                    });
                }
                
                if(this.returnUrl){
                    this.router.navigateByUrl(this.returnUrl);
                } else {
                    this.router.navigate(['app/'+tabName], { queryParams: { } });
                }
                
            });
        }).catch(err=>{
            this.flashMessagesFunction("Please check your stripe Subscription", 'danger', 2500 );
        })
    }

    async checkStripeSubscription(){
       await new Promise<void>((resolve, reject)=>{
            this.authService.checkStripeSubscription().subscribe(res=>{
                if(res && res['url'] === 'dashboard'){
                   resolve();
                } else {
                    this.router.navigate(['subscribe/' + res['url']],{});
                }
            });
        });       
    }

    otpLogin(user) {
        this.spinner.show();
        this.commonService.showLoader();
        this.authService.login(user).subscribe(usuario =>{
            this.commonService.hideLoader();
            let timeOutVal = 2500;
            if (usuario['message'] && usuario['message'].length) {
                if (usuario['message'] === 'Please enter valid OTP') {
                    $('#toggleUserContainer').hide();
                    $('#correctOtpContainer').show();
                }
                if (usuario['message'] === 'Entered OTP is expired and please click on Resend OTP link') {
                    timeOutVal = 2500;
                    $('#toggleUserContainer').hide();
                    $('#correctOtpContainer').show();
                    $('#correctLoginOtp').val('');
                }
                if (usuario['message'] === this.lockedMessage) {
                    timeOutVal = 4500;
                    $('#otpContainer, #correctOtpContainer').hide();
                    $('input').val('');
                    $('#userNameContainer, #passwordContainer, #siginLinksContainer, #signBtn').show();
                    if(this.toggleUser === 'yes'){
                        $('#toggleUserContainer').show();
                    }
                    $('#siginLinksContainer').addClass('links-inactive');
                }
                this.flashMessagesFunction(usuario['message'], 'danger', timeOutVal);
            } else {
                this.userId = usuario[0].id;
                //setUserSessionStorageData(usuario);
                if ((usuario[0].is_verified === 0 || usuario[0].is_verified === null) && usuario[0].role === 'Agent' ) {
                    if ($('#rememberOtp').prop('checked')) {
                        if (user.npnFlag === true) {
                            this.setCookie('agentvizion-' + usuario[0].npn, usuario[0].otp, 90, usuario[0].npn, user.npnFlag);
                        } else {
                            this.setCookie('agentvizion-' + usuario[0].email, usuario[0].otp, 90, usuario[0].email, user.npnFlag);
                        }
                    }
                    $('#otpContainer, #correctOtpContainer, #siginLinksContainer, #forgotPasswordEmailContainer, #signBtn').remove();
                    $('#userNameContainer, #npnContainer, #passwordContainer, #siginLinksContainer, #signBtn').hide();
                    $('#otpContainer, #correctOtpContainer').hide();
                    $('#optinalNPNContainer').show();
                    this.verifyUser = true;
                } else if (usuario[0].showForgotPasswordPage) {
                    this.router.navigateByUrl(usuario[0].forgotpasswordLink);
                } else {
                    this.acceptEula(user,usuario)
                    if ($('#rememberOtp').prop('checked')) {
                        if (user.npnFlag === true) {
                            this.setCookie('agentvizion-' + usuario[0].npn, usuario[0].otp, 90, usuario[0].npn, user.npnFlag);
                        } else {
                            this.setCookie('agentvizion-' + usuario[0].email, usuario[0].otp, 90, usuario[0].email, user.npnFlag);
                        }
                        this.navigateToHomePage(user);
                    } else {
                        this.navigateToHomePage(user);
                    }
                }
            }
        });
    }

    setCookie(cname, cvalue, exdays, email, npnFlagVal) {
        var d = new Date();
        d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
        var expires = 'expires=' + d.toUTCString();
        document.cookie = cname.toLowerCase() + '=' + cvalue + ';' + expires + ';path=/;secure;';
        this.authService.setCookieOtp({ 'userName': email, 'otp': cvalue, 'npnFlag': npnFlagVal }).toPromise();
    }

    npnUserLogin(data:any) {
        var user = {
            npnFlag: true,
            username: this.user['npn'],
            password: this.user['password'],
            otp: data.otp,
            isCookieSet: ''
        };
        //if (user.username.length && user.password.length) {
        var eulaFlag = 1;
        if ($('#eulaAgreement').is(':visible') && !$('#eula').is(':checked')) {
            this.flashMessagesFunction('Please accept the End-User License Agreement', 'danger', this.flashMessageDefaultTime);
        } else {
            this.spinner.show();
            this.commonService.showLoader();
            if (user.username && user.password && user.otp) {
                this.setOtp(user);
            } else if ($('#otpContainer').is(':visible')) {
                if ($('#otp').is(':visible') && user.otp) {
                    this.loginUsingOtp(user);
                } else {
                    this.commonService.hideLoader();
                    this.flashMessagesFunction('Please enter valid OTP', 'danger', this.flashMessageDefaultTime);
                }
            } else {
                if (this.getCookie('agentvizion-' + user.username)) {
                    this.user['isCookieSet'] = this.getCookie('agentvizion-' + user.username);
                    user['isCookieSet'] = this.user['isCookieSet'];
                }
                this.npnlogin(user);
            }
        }
        // } else {
        //     if (user.password === '' && user.username === '') {
        //         $scope.flashMessageStyle(red, 'Please enter username and password');
        //     } else if (user.password === '') {
        //         $scope.flashMessageStyle(red, 'Please enter password');
        //     }
        // }
    }

    emailUserLogin(data:any) {
        this.spinner.show();
        var user = {
            npnFlag: false,
            username: this.user['username'],
            password: this.user['password'],
            otp: data.otp
        };
        //if (user.username.length && user.password.length) {
        var eulaFlag = 1;
        if ($('#eulaAgreement').is(':visible') && !$('#eula').is(':checked')) {
            this.flashMessagesFunction('Please accept the End-User License Agreement', 'danger', this.flashMessageDefaultTime);
        } else {
            this.spinner.show();
            this.commonService.showLoader();
            if (user.username && user.password && user.otp) {
                this.setOtp(user);
            } else if ($('#otpContainer').is(':visible')) {
                if ($('#otp').is(':visible') && user.otp) {
                    this.loginUsingOtp(user);
                } else {
                    this.commonService.hideLoader();
                    this.flashMessagesFunction('Please enter valid OTP', 'danger', this.flashMessageDefaultTime);
                }
            } else {
                if (this.getCookie('agentvizion-' + user.username.toString().toLowerCase())) {
                    this.user['isCookieSet'] = this.getCookie('agentvizion-' + user.username.toString().toLowerCase());
                    user['isCookieSet'] = this.user['isCookieSet'];
                }
                this.npnlogin(user);
            }
        }
    }

    npnlogin(user:any) {
        user = this.user;
        this.spinner.show();
        this.commonService.showLoader();
        if ((user.username === '' || user.npn === '') && user.password === '') {
            this.flashMessagesFunction('Please enter valid credentials', 'danger', this.flashMessageDefaultTime);
             this.commonService.hideLoader();
        } else if (user.username === '' && this.npnFlag === false ) {
            this.flashMessagesFunction('Please enter valid email', 'danger', this.flashMessageDefaultTime);
            this.commonService.hideLoader();
        } else if (this.npnFlag && user.npn === '') {
            this.flashMessagesFunction(this.enterNPNMsg, 'danger', this.flashMessageDefaultTime);
            this.commonService.hideLoader();
        } else if (user.password === '') {
            this.flashMessagesFunction('Please enter valid password', 'danger', this.flashMessageDefaultTime);
             this.commonService.hideLoader();
        } 
        // else if ((user.username === '' || user.npn === '') && user.password !== '') {
        //     if (this.npnFlag === false) {
        //         this.flashMessagesFunction('Please enter valid email', 'danger', this.flashMessageDefaultTime);
        //     } else {
        //         this.flashMessagesFunction(this.enterNPNMsg, 'danger', this.flashMessageDefaultTime);
        //     }
        //     this.commonService.hideLoader();
        // }
        else {
            this.authService.isCookieOtpValid(user).subscribe(response => {
                var cookieValue = decodeURIComponent(document.cookie);
                var cookieValue = decodeURIComponent(document.cookie);
                if(response){
                    if (response['cookie_otp'] && cookieValue) {
                        var cookieSplitValue = cookieValue.split('=');
                        if (response['cookie_otp'] === cookieSplitValue[1] + '=') {
                            this.user['isCookieSet'] = cookieSplitValue[1] + '=';
                            user['isCookieSet'] =  this.user['isCookieSet'];
                        }
                    } else {
                        this.user['isCookieSet'] = this.getCookie('agentvizion-' + user.username);
                        user['isCookieSet'] =  this.user['isCookieSet'];
                    }
                    this.authService.login(user).subscribe(usuario => {
                        this.spinner.show();
                        this.commonService.showLoader();
                        if (usuario['message'] && usuario['message'].length) {
                            $('#siginLinksContainer').removeClass('links-inactive');
                            let timeoutVal = 1000;
                            if (usuario['message'] === this.invalidLoginAttemptMsg) {
                                this.delete_cookie('agentvizion-' + user.username, user.username, user.npnFlag);
                                $('#toggleUserContainer, #userNameContainer, #npnContainer, #passwordContainer, #siginLinksContainer, #correctOtpContainer, #signBtn, #eulaAgreement').hide();
                                $('#otpContainer').show();
                            }
                            if (usuario['message'] === this.lockedMessage) {
                                timeoutVal = 4500;
                                this.delete_cookie('agentvizion-' + user.username, user.username, user.npnFlag);
                                $('#otpContainer, #correctOtpContainer').hide();
                                $('input').val('');
                                $('#userNameContainer, #passwordContainer, #siginLinksContainer, #signBtn').show();
                                $('#siginLinksContainer').addClass('links-inactive');
                                $('#signInBtn').addClass("disabled links-inactive")
                            }
                            if(usuario['message'] === this.lockedMessage){
                                timeoutVal = 4500;
                                $('#siginLinksContainer').addClass('links-inactive');
                                $('#signInBtn').addClass("disabled links-inactive")
                            }
                            this.flashMessagesService.show(usuario['message'], { cssClass: 'alert-danger', timeout: timeoutVal });
                        } else {
                            this.authService.setUserInfo(usuario[0]);
                            this.userId = usuario[0].id;
                            if (usuario[0].showOtpInputBox) {
                                if (this.getCookie('agentvizion-' + user.username)) {
                                    this.authService.getCookieOtp({ 'userName': user.username, 'otp': this.getCookie('agentvizion-' + user.username), 'npnFlag': user.npnFlag }).subscribe(res => {
                                        if (res) {
                                            if (!usuario[0].is_verified && usuario[0].role === 'Agent') {
                                                //this.flashMessagesFunction('', 'success');
                                                $('#otpContainer, #correctOtpContainer, #siginLinksContainer, #forgotPasswordEmailContainer, #signBtn').remove();
                                                $('#userNameContainer, #npnContainer, #passwordContainer, #siginLinksContainer, #signBtn').hide();
                                                $('#otpContainer, #correctOtpContainer, #eulaAgreement').hide();
                                                $('#optinalNPNContainer').show();
                                                this.verifyUser = true;
                                            } else {
                                                this.navigateToHomePage(user);
                                            }
                                        } else {
                                            $('#userNameContainer, #npnContainer, #passwordContainer, #siginLinksContainer, #optinalNPNContainer, #eulaAgreement').hide();
                                            $('#otpContainer, #signBtn').hide();
                                            $('#toggleUserContainer').hide();
                                            $('#correctOtpContainer').show();
                                            $('#correctLoginOtp').focus();
                                            this.flashMessagesFunction(this.enterOTPMsg, 'success', this.flashMessageDefaultTime);
                                        }
                                    });
                                } else {
                                    this.lockUsername = true;
                                    $('#userNameContainer, #npnContainer, #passwordContainer, #siginLinksContainer, #optinalNPNContainer, #eulaAgreement, #emailUserNameContainer',).hide();
                                    $('#otpContainer, #signBtn').hide();
                                    $('#toggleUserContainer').hide();
                                    $('#correctOtpContainer').show();
                                    $('#correctLoginOtp').focus();
                                    this.flashMessagesFunction(this.enterOTPMsg, 'success', this.flashMessageDefaultTime);
                                }
                            } else if (usuario[0].showForgotPasswordPage) {
                                console.log('ssssssss415', usuario[0].forgotpasswordLink, usuario[0]['forgotpasswordLink']);
                                this.router.navigateByUrl(usuario[0].forgotpasswordLink);
                            } else {
                                this.navigateToHomePage(user);
                            }
                        }
                        this.commonService.hideLoader();
                    });
                } else {
                    this.spinner.hide();
                    this.commonService.hideLoader();
                    this.flashMessagesFunction('User not found', 'danger', this.flashMessageDefaultTime);
                }
            });
        }
    }

    forgotPassword = function(){
        var emailId = $('#getEmail').val();
        var emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        var emailValidate = emailRegex.test(String(emailId).toLowerCase());
        var userValue = $('ul#toggleTab').find('li.active').text().trim();
        if (userValue === 'EMAIL USER' || userValue === '') {
            if (emailValidate) {
                let reqObj: object = { 'param1': emailId };
                this.authService.checkForgotEmailIsValid(reqObj).subscribe(res => {
                    let response = JSON.parse(res);
                    if (response && response.length) {
                        if(response[0].is_verified === 1 && (response[0].is_blocked === 0 || response[0].is_blocked === null)){
                            let reqObject = { 'username': response[0].email }
                            this.authService.sendForgotPassword(reqObject).subscribe(res => {
                                this.flashMessagesFunction(this.forgotPasswordMsg, 'success', this.flashMessageDefaultTime);
                                this.cancel();
                            });
                        } else if(response[0].is_blocked === 1) {
                            this.flashMessagesFunction(this.lockedMessage, 'danger', this.flashMessageDefaultTime);
                        } else {
                                this.flashMessagesFunction('Invalid Action. Please login with the password provided in the welcome email.', 'danger', this.flashMessageDefaultTime);
                        }
                    } else {
                        this.flashMessagesFunction('Email Not Found!', 'danger', this.flashMessageDefaultTime);
                    }
                });
            } else {
                this.flashMessagesFunction('Please enter valid Emailid!', 'danger', this.flashMessageDefaultTime);
            }
        } else {
            let npn: any = $('#getnpn').val();
            //var npnRegex = /^[0-9]{1,7}$/;
            //var npnValidate = npnRegex.test(npn);
            if (npn && npn.length) {
                let reqObj: object = { 'param1': npn, npnFlag: true };
                this.authService.checkForgotEmailIsValid(reqObj).subscribe(res => {
                    let response = JSON.parse(res);
                    if (response) {
                        if(response[0].is_verified ===1  && (response[0].is_blocked === 0 || response[0].is_blocked === null)){
                            let reqObject = { 'username': response[0].email }
                            this.authService.sendForgotPassword(reqObject).subscribe(res => {
                                this.flashMessagesFunction(this.forgotPasswordMsg, 'success', this.flashMessageDefaultTime);
                                this.cancel();
                            });
                        } else if(response[0].is_blocked === 1) {
                            this.flashMessagesFunction(this.lockedMessage, 'danger', this.flashMessageDefaultTime);
                        } else {
                            this.flashMessagesFunction('Invalid Action. Please login with the password provided in the welcome email.', 'danger', this.flashMessageDefaultTime);
                        }
                    } else {
                        this.flashMessagesFunction('NPN Not Found!', 'danger', this.flashMessageDefaultTime);
                    }
                });
            } else {
                this.flashMessagesFunction(this.enterNPNMsg, 'danger', this.flashMessageDefaultTime);
            }
        }
    }

    delete_cookie(name, email, npnFlagVal) {
        document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:01 GMT;secure;';
        this.authService.setCookieOtp({ 'userName': email, 'otp': '', 'npnFlag': npnFlagVal });
    }

    getCookie(cname) {
        var name = cname + '=';
        var decodedCookie = decodeURIComponent(document.cookie);
        var ca = decodedCookie.split(';');
        for (let i = 0; i < ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) === ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) === 0) {
                return c.substring(name.length, c.length);
            }
        }
        return '';
    }

    checkRefreshToken() {
        this.authService.checkRefreshToken().subscribe((result:any) => {
            this.user['refreshToken'] = this.localStorageService.get('_rt');
        },
         (err: HttpErrorResponse) => {
            this.localStorageService.remove('_rt');
            this.localStorageService.remove('userInfo');
            delete this.user['refreshToken']
         })
    }

    loginCheckNew(data: any, loginFlag: boolean = false) {
        let payload;
        if(this.loginScreen) {
            payload = {...this.user};
        } else {
            payload = {...data};
        }
        
        if(this.npnFlag) {
            payload.npnFlag = true;
            payload.username = this.user['npn'];
        }
        if(!this.npnFlag) {
            if (this.loginScreen && !this.checkForValidEmail(payload['username'])) {
                this.flashMessagesFunction('Please enter valid email', 'danger', this.flashMessageDefaultTime);
                return;
            }
        } else {
            if (this.loginScreen && payload['username'] === "") {
                this.flashMessagesFunction('Please enter valid npn', 'danger', this.flashMessageDefaultTime);
                return;
            }
        }
        if (this.loginScreen && payload['password'] === "") {
          this.flashMessagesFunction('please enter valid credentials', 'danger', this.flashMessageDefaultTime);
          return;
        }
        if (!this.loginScreen && !this.resentOtpFlag && payload['otp'] === "") {
          this.flashMessagesFunction('please enter otp', 'danger', this.flashMessageDefaultTime);
          return;
        }
        if(this.loginScreen) {
          delete payload['rememberMe'];
          this.otpScreenUserData = { ...this.user };
        } else {
          delete payload['refreshToken'];
        }
        
        this.spinner.show();
        this.authService.login(this.removeNullValues(payload)).subscribe((result:any) => {
          this.spinner.hide();
          if (result.message) {
            this.flashMessagesFunction(result.message, 'danger', this.flashMessageDefaultTime);
            if(result.showForgotPasswordPage) {
                this.showForgotPassword();
            }
            if(!this.loginScreen) {
                if(result.message == this.lockedMessage) {
                    this.loginUsingOtpCancel(this.otpScreenUserData)
                }
            }
            return;
          }
          if (this.loginScreen) {
            // store useInfo.
            this.localStorageService.set('userInfo', result[0]);
            if(result[0]?.role == 'Agent' && result[0]?.is_verified == 0) {
                this.isAgentVerified = false;
            }
            if(result?.refreshToken) {
              this.localStorageService.set('userInfo', result.userInfo[0]);
              this.localStorageService.set('_t', result.authToken);
              this.localStorageService.set('_rt', result.refreshToken);
              this.navigateToHomePage(payload);
              return ;
            }
            this.loginScreen = false;
            this.resentOtpFlag = false;
            $('#userNameContainer, #npnContainer, #passwordContainer, #siginLinksContainer, #optinalNPNContainer, #eulaAgreement').hide();
            $('#otpContainer, #signBtn').hide();
            $('#toggleUserContainer').hide();
            $('#correctOtpContainer').show();
            $('#correctLoginOtp').focus();
            this.flashMessagesFunction(this.enterOTPMsg, 'success', this.flashMessageDefaultTime);
          } else {
            // tokens authtoken and refresh token.
            // this.localStorageService.set('userInfo', result.userInfo[0]);
            this.localStorageService.set('_t', result.authToken);
            this.localStorageService.set('_rt', result.refreshToken);

            if(!this.isAgentVerified) {
                $('#userNameContainer, #npnContainer, #passwordContainer, #siginLinksContainer, #correctOtpContainer, #eulaAgreement').hide();
                $('#otpContainer, #signBtn').hide();
                $('#toggleUserContainer').hide();
                $('#optinalNPNContainer').show();
                $('#optionalNPN').focus();
                // this.checkOptionalNPN(payload);
                return;
            }
            this.navigateToHomePage(payload);
          }
          // redirect to otp screen.
        }, (err: HttpErrorResponse) => {
            this.spinner.hide();
            console.log(err.error);
            if (err.error) {
                this.flashMessagesFunction(err.error.message, 'danger', this.flashMessageDefaultTime);
            }
        })
      }
    
      removeNullValues(obj: any) {
        let tempObj = { ...obj };
        Object.keys(obj).forEach(key => {
          if ( typeof obj[key] !== 'boolean' && !obj[key]) {
            delete tempObj[key];
          }
        });
        return tempObj;
      }

    loginCheck(data: any, loginFlag:any) {
        if(!this.checkForValidEmail(this.user.username)) {
            this.flashMessagesFunction('Please enter valid email', 'danger', this.flashMessageDefaultTime);
            return;
        }
        this.spinner.show();
        this.commonService.showLoader();
        if (loginFlag === true) {
            data.otp = '';
        }
        this.npnFlag = false;
        var userValue = $('ul#toggleUser').find('li a.active').text().trim();
        if (this.toggleUser === 'yes') {
            if (userValue === 'EMAIL USER') {
                this.npnFlag = false;
                this.emailUserLogin(data);
            } else if (userValue === 'NPN USER') {
                this.npnFlag = true;
                this.npnUserLogin(data);
            }
        } else {
            this.emailUserLogin(data);
        }
    };

    login(user:any) {
        user = this.user;
        this.spinner.show();
        this.commonService.showLoader();
        if ((user.username === '' || user.npn === '') && user.password === '') {
            this.flashMessagesFunction('Please enter valid credentials', 'danger', this.flashMessageDefaultTime);
            this.commonService.hideLoader();
        }else if (user.username === '' && this.npnFlag === false ) {
            this.flashMessagesFunction('Please enter valid email', 'danger', this.flashMessageDefaultTime);
            this.commonService.hideLoader();
        } else if (this.npnFlag && user.npn === '') {
            this.flashMessagesFunction(this.enterNPNMsg, 'danger', this.flashMessageDefaultTime);
            this.commonService.hideLoader();
        } else if (user.password === '') {
            this.flashMessagesFunction('Please enter valid password', 'danger', this.flashMessageDefaultTime);
             this.commonService.hideLoader();
        }
        // else if ((user.username !== '' || user.npn !== '') && user.password === '') {
        //     this.flashMessagesFunction('Please enter valid password', 'danger', this.flashMessageDefaultTime);
        //     this.commonService.hideLoader();
        // } else if ((user.username === '' || user.npn === '') && user.password !== '') {
        //     if (this.npnFlag === false) {
        //         this.flashMessagesFunction('Please enter valid email', 'danger', this.flashMessageDefaultTime);
        //     } else {
        //         this.flashMessagesFunction(this.enterNPNMsg, 'danger', this.flashMessageDefaultTime);
        //     }
        //     this.commonService.hideLoader();
        // }
         else {
            this.user['isCookieSet'] = this.getCookie('agentvizion-' + user.username.toLowerCase());
                this.authService.login(user).subscribe(usuario =>{
                if (usuario['message'] && usuario['message'].length) {
                    $('#siginLinksContainer').removeClass('links-inactive');
                    if (usuario['message'] === this.invalidLoginAttemptMsg) {
                        this.delete_cookie('agentvizion-' + user.username.toLowerCase(), user.username, user.npnFlag);
                        $('#otpContainer').show();
                        $('#toggleUserContainer, #userNameContainer, #npnContainer, #passwordContainer, #siginLinksContainer, #correctOtpContainer, #signBtn, #eulaAgreement').hide();
                    }
                    if (usuario['message'] === this.lockedMessage) {
                        this.delete_cookie('agentvizion-' + user.username.toLowerCase(), user.username, user.npnFlag);
                        $('#otpContainer, #correctOtpContainer').hide();
                        $('input').val('');
                        if (this.toggleUser === 'yes') {
                            if (user.npnFlag === true) {
                                $('#toggleUserContainer, #npnContainer').show();
                            } else {
                                $('#toggleUserContainer, #emailUserNameContainer').show();
                            }
                        } else {
                            $('#userNameContainer').show();
                        }
                        $('#passwordContainer, #siginLinksContainer, #signBtn').show();
                        $('#siginLinksContainer').addClass('links-inactive');
                    }
                    this.flashMessagesFunction(usuario['message'], 'danger', this.flashMessageDefaultTime);
                } else {
                    this.userId = usuario[0].id;
                    //setUserSessionStorageData(usuario);
                    if (usuario[0].showOtpInputBox) {
                        if (this.getCookie('agentvizion-' + user.username.toLowerCase())) {
                            this.authService.getCookieOtp({ 'userName': user.username, 'otp': this.getCookie('agentvizion-' + user.username.toLowerCase()), 'npnFlag': user.npnFlag }).subscribe(res =>{
                                if (res) {
                                    if (!usuario[0].is_verified && usuario[0].role === 'Agent') {
                                        $('#otpContainer, #correctOtpContainer, #siginLinksContainer, #forgotPasswordEmailContainer, #signBtn').remove();
                                        $('#userNameContainer, #npnContainer, #passwordContainer, #siginLinksContainer, #signBtn').hide();
                                        $('#otpContainer, #correctOtpContainer, #eulaAgreement').hide();
                                        $('#optinalNPNContainer').show();
                                        this.verifyUser = true;
                                    } else {
                                        this.navigateToHomePage(user);
                                    }
                                } else {
                                    $('#userNameContainer, #npnContainer, #passwordContainer, #siginLinksContainer, #optinalNPNContainer, #eulaAgreement').hide();
                                    $('#otpContainer, #signBtn').hide();
                                    $('#toggleUserContainer').hide();
                                    $('#correctOtpContainer').show();
                                    $('#correctLoginOtp').focus();
                                    this.flashMessagesFunction(this.enterOTPMsg, 'success', this.flashMessageDefaultTime);
                                }
                            });

                        } else {
                            $('#userNameContainer, #npnContainer, #passwordContainer, #siginLinksContainer, #optinalNPNContainer, #eulaAgreement').hide();
                            $('#otpContainer, #signBtn').hide();
                            $('#toggleUserContainer').hide();
                            $('#correctOtpContainer').show();
                            $('#correctLoginOtp').focus();
                            this.flashMessagesFunction(this.enterOTPMsg, 'success', this.flashMessageDefaultTime);
                        }
                    } else if (usuario[0].showForgotPasswordPage) {
                        // this.routeParams = {
                        //     url: "/forgot-password",
                        //     queryParam: ''
                        // }
                        // this.router.navigate([this.routeParams['url']], { queryParams: { } });
                        this.router.navigateByUrl(usuario[0].forgotpasswordLink);
                    } else {
                        this.navigateToHomePage(user);
                    }
                }
                this.commonService.hideLoader();
            });
        }
    }

    loginUsingOtpOnly(user) {
        this.authService.loginUsingOtp(user).subscribe(usuario => {
            this.commonService.hideLoader();
            if (usuario['message'] && usuario['message'].length) {
                if (usuario['message'] === this.lockedMessage) {
                    $('#otpContainer, #correctOtpContainer').hide();
                    $('input').val('');
                    if (this.toggleUser === 'yes') {
                        if (user.npnFlag === true) {
                            $('#toggleUserContainer, #npnContainer').show();
                        } else {
                            $('#toggleUserContainer, #emailUserNameContainer').show();
                        }
                    } else {
                        $('#userNameContainer').show();
                    }
                    $('#passwordContainer, #siginLinksContainer, #signBtn').show();
                    $('#siginLinksContainer').addClass('links-inactive');
                }
                this.flashMessagesFunction(usuario['message'], 'danger', this.flashMessageDefaultTime);
            } else {
                this.router.navigate([usuario[0].forgotpasswordLink], { queryParams: { } });
            }
        });
    }

    setOtp(user) {
        this.authService.setUserOtp({ 'param1': user.username, 'param2': user.otp, 'param3': user.npnFlag }).subscribe(usuario =>{
            this.login(user);
        });
    }

    loginUsingOtp(user) {
        this.authService.setUserOtp({ 'param1': user.username, 'param2': user.otp, 'param3': user.npnFlag }).subscribe(usuario =>{
            this.loginUsingOtpOnly(user);
        });
    }

    cancel(){
        $('.loginForm').show();
        this.forgotVisible = true;
        $('#otpContainer').hide();
        this.npnFlag = false;
        this.lockUsername = false;
        // $('#username').val('');
        // $('#username').focus();
    };

    redirectToLoginPage() {
        const routerState = this.router.routerState.snapshot;
        this.router.navigate([this.routeParams['url']], { queryParams: { } });
    }

    showForgotPassword() {
        this.npnFlag = false;
        $('.loginForm').hide();
        $('#forgetNpnContainer, .signInTitle').hide();
        $('#forgetEmailContainer').show();
        this.forgotVisible = false;
        $('.fix label').css('color', 'rgba(0,0,0,.87)');
        $('.fix label').css('float', 'left');
        $('#getEmail').val('');
        $('#getnpn').val('');
        $('#getEmail').focus();
    }

    resendOtp(user:any) {
        // this.authService.sendOtp(user).subscribe(res => {
        //     if (res['msg'] === 'OTP has been sent to your email') {
        //         this.flashMessagesFunction(res['msg'], 'success', this.flashMessageDefaultTime);
        //     } else {
        //         this.flashMessagesFunction(res['msg'], 'danger', this.flashMessageDefaultTime);
        //     }
        // });
        this.resentOtpFlag = true;
        this.loginScreen = true;
        user['otp'] = '';
        this.loginCheckNew(user, true);
    }

    showChangePassword() {
        this.routeParams = {
            url: "/auth/change-password",
            queryParam: ''
        }
        this.redirectToLoginPage()

    };

    showForgotUsername() {
        this.routeParams = {
            url: "/auth/forgot-username",
            queryParam: ''
        }
        this.redirectToLoginPage()
    };

    emailOrNPNTab = function(val: any) {
        // this.flashMessagesFunction('', 'danger', this.flashMessageDefaultTime);
        if (val === 'npn') {
            $('#emailUserNameContainer').hide();
            $('#npnContainer').show();
            document.getElementById("npn-tab").classList.add("active");
            document.getElementById("email-tab").classList.remove("active");
            this.npnFlag = true;
        } else {
            $('#npnContainer').hide();
            $('#emailUserNameContainer').show();
            document.getElementById("email-tab").classList.add("active");
            document.getElementById("npn-tab").classList.remove("active");
            this.npnFlag = false;
        }
    };

    toggleTab = function(val) {
        // this.flashMessagesFunction('', 'success', this.flashMessageDefaultTime);
        if (val === 'npn') {
            this.npnFlag = true;
            $('#forgetEmailContainer').hide();
            $('#forgetNpnContainer').show();
        } else {
            this.npnFlag = false;
            $('#forgetNpnContainer').hide();
            $('#forgetEmailContainer').show();
        }
    }

    checkNpnOtp = function(user: any) {
        user.password = '';
        var userNpn = user.npn;
        if (userNpn) {
            this.authService.checkNpnOtp({ usernpn: userNpn }).subscribe(obj => {
                var res = obj.msg;
                this.isAgree = obj.isEulaApplicable ? false : true;
                // this.isEulaAccepted = obj.isEulaApplicable;
                if (res === this.invalidLoginAttemptMsg) {
                    this.flashMessagesFunction(res, 'danger', this.flashMessageDefaultTime);
                    // $('#signInBtn').button('loading');
                    $('#siginLinksContainer, #correctOtpContainer, #otpContainer').hide();
                    setTimeout(function() {
                        // $('#signInBtn').button('reset');
                        $('#siginLinksContainer').removeClass('links-inactive');
                        $('#toggleUserContainer, #userNameContainer, #npnContainer, #passwordContainer, #siginLinksContainer, #correctOtpContainer, #signBtn').hide();
                        $('#otpContainer').show();
                        this.resendOtp(user);
                    }, 3000);
                } else if (res === this.lockedMessage) {
                    $('#siginLinksContainer').addClass('links-inactive');
                    this.flashMessagesFunction(res, 'danger', this.flashMessageDefaultTime);
                } else if (res === 'User unblocked') {
                    $('#siginLinksContainer').removeClass('links-inactive');
                    //this.flashMessagesFunction('', 'danger', this.flashMessageDefaultTime);
                } else if (res === this.accountVerificationMsg) {
                        $("ul .active").removeClass("active");
                        $("ul li:first").addClass("active");
                        this.emailOrNPNTab();
                        $('#toggleUser li:last-child').remove();
                        this.flashMessagesFunction(res, 'danger', this.flashMessageDefaultTime);
                } else {
                    if (res !== '' || res.length === 0) {
                        this.flashMessagesFunction(res, 'danger', this.flashMessageDefaultTime);
                        $('#siginLinksContainer').addClass('links-inactive');
                    } else {
                        this.flashMessagesFunction(res, 'success', this.flashMessageDefaultTime);
                    }
                     $('#siginLinksContainer').removeClass('links-inactive');
                }
            });
        } else {
            this.flashMessagesFunction(this.enterNPNMsg, 'danger', this.flashMessageDefaultTime);
        }
    };

    checkEmailOtp(user) {
        if (!this.loginScreen) {
            return;
        }
        user.password = '';
        var username = this.user.username;
        var emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        var emailValidate = emailRegex.test(String(username).toLowerCase());
        if (emailValidate) {
            this.authService.checkEmailOtp({ emailId: username }).subscribe(obj=> {
                var res = obj.msg;
                this.isAgree = obj.isEulaApplicable ? false : true;
                this.acceptEulaFlag = obj.isEulaApplicable;
                this.isEulaAccepted = obj.isEulaApplicable;
                
                $('#correctOtpContainer, #optinalNPNContainer').hide();
            });
        } else {
            this.flashMessagesFunction('Please enter valid email', 'danger', this.flashMessageDefaultTime);
            $('#correctOtpContainer, #optinalNPNContainer').hide();
        }
    };

    checkOptionalNPN(user: any) {
        var username = user.username;
        var optionalNPN = $('#optionalNPN').val();
        if (optionalNPN == "") {
            this.flashMessagesFunction('please enter valid npn', 'danger', this.flashMessageDefaultTime);
            return;
        }
        var npnRegex = /^\d+$/;
        var isNPNValidate = npnRegex.test(String(optionalNPN).toLowerCase());
        if (isNPNValidate) {
            this.spinner.show();
            this.authService.checkOptionalNPN({ primaryEmail: username, npn: optionalNPN }).subscribe(res => {
                if (res && res['msg'] === 'NPN Verified Successfully') {
                    $('#optinalEmailSubmitBtn').addClass('btns-inactive');
                    // $('#optinalEmailSubmitBtn').button('loading');
                    this.flashMessagesFunction(this.accountVerifiedMsg, 'success', this.flashMessageDefaultTime);
                    setTimeout(()=> {
                        // var paramObj = {'param1': user.username, 'param2': user.npnFlag, 'param3': this.userId, 'param4': '' }
                        // this.authService.acceptEula(paramObj, function() {});
                        this.isAgentVerified = true;
                        this.navigateToHomePage(user);
                        $('#optinalEmailSubmitBtn').removeClass('btns-inactive');
                        // $('#optinalEmailSubmitBtn').button('reset');
                        this.spinner.hide();
                    }, 3000);
                } else if (res && res['msg'] === this.npnLockedMessage) {
                    this.flashMessagesFunction(res['msg'], 'danger', this.flashMessageDefaultTime);
                    // $('#optinalEmailSubmitBtn').addClass('btns-inactive');
                    $('#optinalNPNContainer').hide();
                    $('#otpContainer, #correctOtpContainer').hide();
                    $('#userNameContainer, #passwordContainer, #siginLinksContainer, #signBtn').show();
                    this.loginScreen = true;
                    delete this.user['otp'];
                    this.localStorageService.remove('_rt');
                    delete this.user['refreshToken'];
                    setTimeout(function() {
                        $('#optionalNPN').val('');
                    }, 1000);
                } else {
                    this.flashMessagesFunction(res['msg'], 'danger', this.flashMessageDefaultTime);
                    setTimeout(function() {
                        $('#optionalNPN').val('');
                    }, 1000);
                }
            });
            setTimeout(()=> {
                this.spinner.hide();
            }, 3000);
        } else {
            this.flashMessagesFunction('please enter valid npn', 'danger', this.flashMessageDefaultTime);
        }
    };

    loginUsingOtpCancel(user) {
        $('#otpContainer, #correctOtpContainer').hide();
        $('#userNameContainer, #passwordContainer, #siginLinksContainer, #signBtn').show();
        this.loginScreen = true;
        delete this.user['otp']
        return;

        this.lockUsername = false;
        var username = '';
        if (this.npnFlag) {
            username = user.npn;
        } else {
            username = user.username;
        }
        this.authService.resetOtp({ 'param1': username, 'npnFlag': this.npnFlag }).subscribe(res =>{
            if (res === 1) {
                // location.href = '/#/login';
                user.username = '';
                $('input').val('');
                user.otp = '';
                $('#otpContainer, #correctOtpContainer').hide();
                if (this.toggleUser === 'yes') {
                    $('#toggleUserContainer').show();
                    if ($('ul#toggleUser').find('li a.active').text().trim() === 'EMAIL USER') {
                        $('#userNameContainer, #npnContainer').hide();
                        $('#emailUserNameContainer').show();
                    } else {
                        $('#emailUserNameContainer, #userNameContainer').hide();
                        $('#npnContainer').show();
                    }
                } else {
                    $('#toggleUserContainer, #emailUserNameContainer, #npnContainer').hide();
                    $('#userNameContainer').show();
                }

                $('#passwordContainer, #siginLinksContainer, #signBtn').show();
                this.router.navigate(['/login'], { queryParams: { } });
            }
        });
    };

    onlyDigits(evt?) {
        var ASCIICode = (evt.which) ? evt.which : evt.keyCode
        if (ASCIICode > 31 && (ASCIICode < 48 || ASCIICode > 57))
            return false;
        return true;
    };

    modelChange(event){
        if(event !== this.user.username && !this.lockUsername){
            this.user.username =event;
            // this.checkEmailOtp(this.user);
        }
    }

    pwdModelChange(event){
        if(event !== this.user.password && !this.lockUsername){
            this.user.password =event;
        }
    }

    checkForValidEmail(email: string){
        let emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        let emailValidate = emailRegex.test(String(email).toLowerCase());
        return emailValidate;
    }
    
    getSavedSession(sessionId: string){
        if(!sessionId) {
            this.reDirecting=false;
            return
        };
        this.commonService.getSavedSession(sessionId).subscribe((res: any)=>{
            console.log(res);
            if(res[0]&&res[0].metadata){
                let metadata = res[0].metadata;
                try {
                    metadata = JSON.parse(metadata);
                } catch (e) {};
                let keys = Object.keys(metadata);
                for(let k of keys){
                    this.localStorageService.set(k, metadata[k]);
                }
                this.navigateToHomePage({}, true);
            } else {
                this.reDirecting=false;
            }
        })
    }

}

